import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CoreAuthModule } from '@serious-stack/core/auth/angular';
import { IonicModule } from '@ionic/angular';
import {
  BaseChatComponent,
  AttachmentComponent,
  ImagePreviewComponent,
  FilePreviewComponent,
  ChatRoomComponent,
  ImageComponent,
  MessageComposerComponent,
  MessageItemComponent,
  SyncStateComponent,
  FileContentComponent,
  ImageContentComponent,
  TextContentComponent,
  MessageListComponent,
  ReactionBarComponent,
  RemoveReactions,
  ReactionIconComponent,
  ReactionPickerComponent,
  IconLoveComponent,
  IconLikeComponent,
} from '.';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '../../../assets/icons.module';
import { ReusableComponentsModule } from '../../components/reusable-components.module';

@NgModule({
  declarations: [
    BaseChatComponent,
    AttachmentComponent,
    ImagePreviewComponent,
    FilePreviewComponent,
    ChatRoomComponent,
    ImageComponent,
    MessageComposerComponent,
    MessageItemComponent,
    SyncStateComponent,
    FileContentComponent,
    ImageContentComponent,
    TextContentComponent,
    MessageListComponent,
    ReactionBarComponent,
    RemoveReactions,
    ReactionIconComponent,
    ReactionPickerComponent,
    IconLoveComponent,
    IconLikeComponent,
  ],
  imports: [
    IonicModule,
    IconsModule,
    CommonModule,
    CoreAuthModule,
    ReactiveFormsModule,
    FormsModule,
    ReusableComponentsModule,
  ],
  providers: [],
  exports: [
    BaseChatComponent,
    AttachmentComponent,
    ImagePreviewComponent,
    FilePreviewComponent,
    ChatRoomComponent,
    ImageComponent,
    MessageComposerComponent,
    MessageItemComponent,
    SyncStateComponent,
    FileContentComponent,
    ImageContentComponent,
    TextContentComponent,
    MessageListComponent,
    ReactionBarComponent,
    RemoveReactions,
    ReactionIconComponent,
    ReactionPickerComponent,
    IconLoveComponent,
    IconLikeComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChatModule {}
