import { Component, EventEmitter, Input, Output } from '@angular/core';
import checkPasswordStrength from 'check-password-strength';
import { defaultOptions } from './defaul-options';


@Component({
  selector: 'strength-password-progress-bar',
  templateUrl: './strength-password-progress-bar.component.html',
  styleUrls: ['./strength-password-progress-bar.component.scss']
})
export class StrengthPasswordProgressBarComponent {
  @Input() passwordValue = '';
  @Input() strengthPasswordProgressBarClass = '';
  @Input() strengthPasswordPopover: Array<any> = [];
  @Output() strengthCompleted = new EventEmitter<boolean>();
  strengthValues = [0.25, 0.5, 0.75, 1];
  yourOptions: any;

  constructor() {
    this.yourOptions = defaultOptions;
  }

  /**
   * Validates the password strength
   */
  validateStrength() {
    const strength =
        checkPasswordStrength.passwordStrength(this.passwordValue, this.yourOptions);

    (strength.id === 3)
        ? this.strengthCompleted.emit(true)
        : this.strengthCompleted.emit(false);

    return strength;
  }
}
