import {
  Component,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { CustomToast } from '../../models/custom-toast-model.model';

@Component({
  selector: 'custom-toast-alert',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './custom-toast-alert.component.html',
  styleUrls: ['./custom-toast-alert.component.scss']
})
export class CustomToastAlertComponent {
  @Input() customToast: CustomToast | undefined;
  @Output() closeEvent = new EventEmitter<boolean>();

  constructor() {}

  /**
   * Hide toast
   */
  toggleShow() {
    this.closeEvent.emit(false);
  }
}
