import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { CoreAuthAmplifyProvider } from '../providers';
import { AuthRequest } from '../providers/core-auth-store.provider';
import { CoreAuthRepository } from '../repositories';

@Injectable({
  providedIn: 'root',
})
export class CoreAuthCognitoGuard implements CanActivate {
  constructor(
    @Inject(CoreAuthAmplifyProvider.providerToken)
    private readonly coreAuthAmplify: CoreAuthAmplifyProvider.providerType,
    private readonly coreAuthRepository: CoreAuthRepository,
    private readonly navCtrl: NavController
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const redirectError: string = route?.data?.redirectError;

    try {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.IS_LOGGED_IN, 'pending');

      const session = await this.coreAuthAmplify.currentSession();

      this.coreAuthRepository.update(session.getIdToken().payload);

      this.coreAuthRepository.updateRequestStatus(AuthRequest.IS_LOGGED_IN, 'success');

      return true;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.IS_LOGGED_IN, 'error', error);

      if (redirectError) {
        this.navCtrl.navigateRoot(redirectError, { queryParams: { returnUrl: state.url } });
      }

      throw error;
    }
  }
}
