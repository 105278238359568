import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, finalize, from, mergeMap, take } from 'rxjs';
import { CoreAuthAmplifyProvider } from '../providers';
@Injectable()
export class CoreAuthInterceptor implements HttpInterceptor {
  private _requestOngoing: BehaviorSubject<boolean>;
  constructor(
    @Inject(CoreAuthAmplifyProvider.providerToken)
    private readonly coreAuthAmplify: CoreAuthAmplifyProvider.providerType
  ) {
    this._requestOngoing = new BehaviorSubject(false as boolean);
  }
  public intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!request.url.includes(process.env.NG_APP_API_ENDPOINT as string)) {
      return next.handle(request);
    }
    return this._requestOngoing.pipe(
      /**
       * Prevent parrallel requests made to our api when working offline to
       * get around a racing issue.
       * - https://halfserious.atlassian.net/browse/SS-587
       */
      filter((_requestOngoing: boolean) => _requestOngoing === false),
      take(1),
      mergeMap(() => {
        if (process.env.NG_APP_IS_OFFLINE === 'true') {
          this._requestOngoing.next(true);
        }
        return from(this.coreAuthAmplify.currentSession());
      }),
      mergeMap((session: any) => {
        const jwtToken = session.idToken.jwtToken;
        request = request.clone({
          withCredentials: false,
          setHeaders: {
            Authorization: jwtToken,
          },
        });
        return next.handle(request);
      }),
      catchError((error: unknown) => {
        console.warn(error);
        return next.handle(request);
      }),
      finalize(() => {
        if (process.env.NG_APP_IS_OFFLINE === 'true') {
          this._requestOngoing.next(false);
        }
      })
    );
  }
}
