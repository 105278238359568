import {
  Component,
  Input
} from '@angular/core';
import { Router } from '@angular/router';
import { TermsConditionElement } from '../../models/terms-condition-element';
import { privacyPolicyLink, termsConditionLink } from '../../_constants/links';
import { termsConditionsInputType } from '../../_constants/terms-conditions-input-type';

@Component({
  selector: 'terms-conditions',
  templateUrl: './term-conditions.component.html',
  styleUrls: ['./term-conditions.component.scss']
})
export class TermConditionsComponent {
  @Input() globalTermConditionClass = '';
  @Input() customTermsConditions: TermsConditionElement[] = [];

  inputType = termsConditionsInputType;

  // Get the link object with the links constant
  termsConditionLink = termsConditionLink;
  privacyPolicyLink = privacyPolicyLink

  constructor(
    private router: Router,
  ) { }

  /**
   * The function is fired up when user click on terms condition or privacy
   * @param data is terms condition element object
   */
  onClickTermConditionPrivacy(data: TermsConditionElement) {
    if (data.text.includes('termsCondition')) {
      this.router.navigate([this.termsConditionLink.routerPath]);
    } else {
      this.router.navigate([this.privacyPolicyLink.routerPath]);
    }

  }

}
