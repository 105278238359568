import { Pipe, PipeTransform } from '@angular/core';
import { MediaLibraryService } from '../services/media-library.service';
import { MediaType } from '../_constants/media-type';
import { MediaFormat } from '../_constants/media-format';
/*
 * Takes a publicid and return an url (we have to use async pipe to display the asynchronous result instead of a promise)
 * Usage:
 *   publicId | media: type: format
 * Example:
 *   {{ 'xcghsnndkiek2ksk' | media: 'image': 'thumbnail' | async }}
 */
@Pipe({ name: 'media' })
export class MediaLibraryPipe implements PipeTransform {
  constructor(private mediaLibraryService: MediaLibraryService) {}

  async transform(
    publicId: string,
    type?: MediaType,
    format?: MediaFormat,
    duration?: number
  ): Promise<string | undefined> {
    if (type === MediaType.IMAGE) {
      switch (format) {
        case MediaFormat.THUMBNAIL:
          return await this.mediaLibraryService.thumbnailPicture(publicId);
        case MediaFormat.ORIGINAL:
          return await this.mediaLibraryService.originalPicture(publicId);
        case MediaFormat.OPTIMIZED:
          return await this.mediaLibraryService.optimizedPicture(publicId);
        default:
          return await this.mediaLibraryService.originalPicture(publicId);
      }
    } else if (type === MediaType.VIDEO) {
      switch (format) {
        case MediaFormat.SAMPLE_DURATION:
          return await this.mediaLibraryService.sampleDurationVideo(publicId, duration || 10);
        case MediaFormat.ORIGINAL:
          return await this.mediaLibraryService.originalVideo(publicId);
        case MediaFormat.OPTIMIZED:
          return await this.mediaLibraryService.optimizedVideo(publicId);
        case MediaFormat.LOW_QUALITY:
          return await this.mediaLibraryService.lowQualityVideo(publicId);
        case MediaFormat.THUMBNAIL:
          return await this.mediaLibraryService.thumbnailVideo(publicId);
        default:
          return await this.mediaLibraryService.originalVideo(publicId);
      }
    } else if (type === MediaType.PDF) {
      switch (format) {
        case MediaFormat.ORIGINAL:
          return await this.mediaLibraryService.originalPdf(publicId);
        case MediaFormat.THUMBNAIL:
          return await this.mediaLibraryService.thumbnailPdf(publicId);
        default:
          return await this.mediaLibraryService.originalPdf(publicId);
      }
    } else {
      console.error('You must specified a type ' + JSON.stringify(Object.values(MediaType)));
      return;
    }
  }
}
