import { Inject, Injectable } from '@angular/core';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { CoreAuthRepository, CoreAuthSettingsRepository } from '../repositories';
import { CoreAuthAmplifyProvider } from '../providers';
import { AuthRequest } from '../providers/core-auth-store.provider';
import { AmplifyChallengeName } from '../interfaces';
import { getStorageState } from '../providers/core-auth-amplify.provider';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class CoreAuthService {
  constructor(
    @Inject(CoreAuthAmplifyProvider.providerToken)
    private readonly coreAuthAmplify: CoreAuthAmplifyProvider.providerType,
    private readonly coreAuthRepository: CoreAuthRepository,
    private readonly coreAuthSettingsRepository: CoreAuthSettingsRepository,
    private readonly deviceService: DeviceDetectorService
  ) {}

  async signUp({
    email,
    password,
    clientMetadata,
  }: {
    email: string;
    password: string;
    clientMetadata?: { [x: string]: string };
  }) {
    try {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.SIGNUP, 'pending');

      const signup = await this.coreAuthAmplify.signUp({
        username: email.toLowerCase(),
        password,
        attributes: {
          email: email.toLowerCase(),
        },
        clientMetadata: clientMetadata || {},
      });

      this.coreAuthRepository.updateRequestStatus(AuthRequest.SIGNUP, 'success');

      return signup;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.SIGNUP, 'error', error);

      throw error;
    }
  }

  async signIn({ email, password }: { email: string; password: string }) {
    try {
      const defaultConfiguration = this.coreAuthAmplify.configure();
      const storage = getStorageState(this.coreAuthSettingsRepository);

      this.coreAuthAmplify.configure({
        ...defaultConfiguration,
        storage: storage,
      });

      this.coreAuthRepository.updateRequestStatus(AuthRequest.SIGNIN, 'pending');

      this.coreAuthRepository.reset();

      const signin = await this.coreAuthAmplify.signIn(email.toLowerCase(), password);

      this.coreAuthRepository.updateRequestStatus(AuthRequest.SIGNIN, 'success');

      return signin;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.SIGNIN, 'error', error);

      throw error;
    }
  }

  async completeInvitation({
    email,
    temporaryPassword,
    newPassword,
  }: {
    email: string;
    temporaryPassword: string;
    newPassword: string;
  }) {
    try {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.COMPLETE_INVITATION, 'pending');

      const signin: { challengeName: AmplifyChallengeName } = await this.coreAuthAmplify.signIn(
        email.toLowerCase(),
        temporaryPassword
      );

      if (signin.challengeName === AmplifyChallengeName.NEW_PASSWORD_REQUIRED) {
        await this.coreAuthAmplify.completeNewPassword(signin, newPassword);
      }

      this.coreAuthRepository.updateRequestStatus(AuthRequest.COMPLETE_INVITATION, 'success');
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.COMPLETE_INVITATION, 'error', error);

      throw error;
    }
  }

  async signInFederated(provider: 'facebook' | 'google' | CognitoHostedUIIdentityProvider) {
    try {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.SIGNIN_FEDERATED, 'pending');

      this.coreAuthRepository.reset();

      switch (provider) {
        case 'facebook':
          provider = CognitoHostedUIIdentityProvider.Facebook;
          break;
        case 'google':
          provider = CognitoHostedUIIdentityProvider.Google;
      }

      const signInFederated = await this.coreAuthAmplify.federatedSignIn({
        provider,
      });

      this.coreAuthRepository.updateRequestStatus(AuthRequest.SIGNIN_FEDERATED, 'success');

      return signInFederated;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.SIGNIN_FEDERATED, 'error', error);

      throw error;
    }
  }

  async signOut() {
    try {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.SIGNOUT, 'pending');

      await this.coreAuthAmplify.signOut();
      this.coreAuthRepository.reset();

      this.coreAuthRepository.updateRequestStatus(AuthRequest.SIGNOUT, 'success');
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.SIGNOUT, 'error', error);

      throw error;
    }
  }

  async signUpConfirm({ email, code }: { email: string; code: string }) {
    try {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.SIGNUP_CONFIRM, 'pending');

      const signUpConfirm = await this.coreAuthAmplify.confirmSignUp(email.toLowerCase(), code);

      this.coreAuthRepository.updateRequestStatus(AuthRequest.SIGNUP_CONFIRM, 'success');

      return signUpConfirm;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.SIGNUP_CONFIRM, 'error', error);

      throw error;
    }
  }

  async resendCode({ email }: { email: string }) {
    try {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNUP_RESEND_VERIFICATION_CODE,
        'pending'
      );

      const resendCode = await this.coreAuthAmplify.resendSignUp(email.toLowerCase());

      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNUP_RESEND_VERIFICATION_CODE,
        'success'
      );

      return resendCode;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SIGNUP_RESEND_VERIFICATION_CODE,
        'error',
        error
      );

      throw error;
    }
  }

  async resetPassword({ email }: { email: string }) {
    try {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.RESET_PASSWORD, 'pending');

      const resetPassword = await this.coreAuthAmplify.forgotPassword(email.toLowerCase());

      this.coreAuthRepository.updateRequestStatus(AuthRequest.RESET_PASSWORD, 'success');

      return resetPassword;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.RESET_PASSWORD, 'error', error);

      throw error;
    }
  }

  async submitResetPassword({
    email,
    code,
    password,
  }: {
    email: string;
    code: string;
    password: string;
  }) {
    try {
      this.coreAuthRepository.updateRequestStatus(AuthRequest.SUBMIT_RESET_PASSWORD, 'pending');

      const submitResetPassword = await this.coreAuthAmplify.forgotPasswordSubmit(
        email.toLowerCase(),
        code,
        password,
        {
          deviceInformation: this.getDeviceInformation(),
        }
      );

      this.coreAuthRepository.updateRequestStatus(AuthRequest.SUBMIT_RESET_PASSWORD, 'success');

      return submitResetPassword;
    } catch (error) {
      this.coreAuthRepository.updateRequestStatus(
        AuthRequest.SUBMIT_RESET_PASSWORD,
        'error',
        error
      );

      throw error;
    }
  }

  private getDeviceInformation() {
    const deviceInfo = this.deviceService.getDeviceInfo();

    return ''.concat(
      deviceInfo.browser.concat(' '),
      deviceInfo.browser_version.concat(' / '),
      deviceInfo.os.concat(' '),
      deviceInfo.os_version.concat(' ')
    );
  }
}
