import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaLibraryPipe } from './pipes/media-library.pipe';
import { ImgCloudComponent } from './components/img-cloud/img-cloud.component';
import { CloudinaryModule } from '@cloudinary/ng';
import { MediaLibraryServiceConfig } from './services/media-library.service';

@NgModule({
  declarations: [MediaLibraryPipe, ImgCloudComponent],
  imports: [CommonModule, CloudinaryModule],
  exports: [MediaLibraryPipe, ImgCloudComponent],
})
export class MediaLibraryModule {
  static forRoot(config: MediaLibraryServiceConfig): ModuleWithProviders<MediaLibraryModule> {
    return {
      ngModule: MediaLibraryModule,
      providers: [{ provide: MediaLibraryServiceConfig, useValue: config }],
    };
  }
}
