import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CoreAuthConfigProvider,
  CoreAuthRepository,
  CoreAuthService,
  CoreAuthSettingsRepository,
  CoreAuthStoreProvider,
} from '@serious-stack/core/auth/angular';
import { CustomToast } from '../../../../models/custom-toast-model.model';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '../../../../services/navigate.service';
import { RoleName } from '@prisma/client';
import { StoreService } from '../../../../store/store.service';

@Component({
  selector: 'kickflo-frontend-stack-signup',
  templateUrl: './kickflo-frontend-signup.page.html',
  styleUrls: ['./kickflo-frontend-signup.page.scss'],
})
export class SignUpPage implements OnInit, OnDestroy {
  currentLang = this.coreAuthSettingsRepository?.storeValue?.currentLanguage;
  customTermsConditionsData: Array<any> = [];
  customTermsConditionsDataAfter: Array<any> = [];
  customToastData: CustomToast | undefined;
  form: FormGroup;
  headerStatus: string;
  loadingSubscriptions: Array<Subscription> = [];

  strengthPopoverData: Array<any> = [];
  userRole = '';

  showValidateCode = false;
  invalidPassword = false;
  passwordIsStrong = false;
  showToastAlert = false;
  showSpinner = false;
  signUpEmail = false;
  emailAlreadyUsed = false;
  userTypeSelected = true;
  showPassword = false;

  get isSSOActivated() {
    return this.config.features.sso;
  }

  get isSignUpActivated(): boolean {
    return this.config.features.signUp;
  }

  constructor(
    @Inject(CoreAuthConfigProvider.providerToken)
    private readonly config: CoreAuthConfigProvider.providerType,
    private authService: CoreAuthService,
    private coreAuthRepository: CoreAuthRepository,
    private coreAuthSettingsRepository: CoreAuthSettingsRepository,
    private storeService: StoreService,
    private translateService: TranslateService,
    private navigateService: NavigateService
  ) {
    this.form = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/\d/),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/\W/),
      ]),
    });
    this.headerStatus = 'signUp';
  }

  ngOnInit() {
    this.init();
    this.subscribeAuthStatus();
  }

  ngOnDestroy(): void {
    this.coreAuthRepository.updateRequestStatus(CoreAuthStoreProvider.AuthRequest.SIGNUP, 'idle');

    this.loadingSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  ionViewWillEnter() {
    this.setConfirmationData();
  }

  // Get password Field
  get getPassword() {
    return this.form.value.password;
  }

  // Get password Field
  get getEmail() {
    return this.form.value.email;
  }

  signUpWithSSO(provider: Parameters<typeof this.authService['signInFederated']>[0]) {
    return this.authService.signInFederated(provider);
  }

  /**
   * Set the User Role
   * @param role: Rolename Brand or Influencer
   */
  changeUserSelection(role: string) {
    this.userRole = role as RoleName;
    // this.userTypeSelected = true;
  }

  /**
   * Set the Strength Password Completed by the User
   * @param event Event emitted on Password Strong
   */
  setStrengthCompleted(event: any) {
    if (event !== this.passwordIsStrong) {
      this.passwordIsStrong = event;
    }
  }

  init() {
    this.customTermsConditionsData = [
      {
        text: 'signUp.signUpTermsConditions',
        textClass: '',
        inputType: 'text',
      },
      {
        text: 'signIn.termsCondition',
        textClass: 'font-w-600 term-policy-text',
        inputType: 'link',
      },
      {
        text: 'general.and',
        textClass: '',
        inputType: 'text',
      },
      {
        text: 'signIn.privacyPolicy',
        textClass: 'font-w-600 term-policy-text',
        inputType: 'link',
      },
    ];

    this.customTermsConditionsDataAfter = [
      {
        text: 'signUp.signUpTermsConditionsAfter',
        textClass: '',
        inputType: 'text',
      },
      {
        text: 'signIn.termsCondition',
        textClass: 'font-w-600 term-policy-text',
        inputType: 'link',
      },
      {
        text: 'general.and',
        textClass: '',
        inputType: 'text',
      },
      {
        text: 'signIn.privacyPolicy',
        textClass: 'font-w-600 term-policy-text',
        inputType: 'link',
      },
    ];

    this.strengthPopoverData = [
      {
        text: 'strengthPassword.strengthText',
        textValidator: '',
        textClass: '',
      },
      {
        text: '',
        textClass: 'strength-text-bold',
        textValidator: this.translateService.instant('form.strengthPassword'),
      },
    ];
  }

  signUp() {
    if (!this.form.valid) return;

    return this.authService.signUp({
      email: this.form.get('email')?.value,
      password: this.form.get('password')?.value,
      clientMetadata: {
        firstName: this.form.get('firstName')?.value,
        lastName: this.form.get('lastName')?.value,
      },
    });
  }

  /**
   * Set the Sign Up
   * send Form with values
   */
  async subscribeAuthStatus() {
    this.loadingSubscriptions.push(
      this.coreAuthRepository
        .selectRequestStatus(CoreAuthStoreProvider.AuthRequest.SIGNUP)
        .subscribe((status: any) => {
          switch (status.value) {
            case 'pending':
              {
                this.emailAlreadyUsed = false;
                this.showSpinner = true;
              }
              break;
            case 'error':
              {
                if (status.error.code === 'UsernameExistsException') {
                  this.emailAlreadyUsed = true;
                }
                this.showSpinner = false;
              }
              break;
            case 'success':
              {
                this.form.markAsPristine();
                this.showValidateCode = true;
                this.showSpinner = false;
              }
              break;
          }
        })
    );
  }

  /**
   * Send code again to confirm Sing Up
   * @param event Resend code
   */
  sendCodeAgain(event: boolean): void {
    if (event) {
      this.customToastData = {
        customClass: 'alert-toast-class',
        customIcon: 'alert-circle',
        customText: 'resetPassword.resendNewCode',
        customTime: 4000,
        customEmail: this.form.value.email,
      };

      this.showToastAlert = true;

      setTimeout(() => {
        this.showToastAlert = false;
      }, this.customToastData.customTime);

      this.authService.resendCode({ email: this.form.get('email')?.value });
    }
  }

  /**
   * After go Back
   * show the Form
   */
  showForm() {
    this.showValidateCode = false;
    this.signUpEmail = true;
    // this.userTypeSelected = true;

    this.storeService.clearAuthCodeValidationData();
  }

  /**
   * Reset form and redirect to login
   */
  signUpCompleted() {
    this.showValidateCode = false;
    this.signUpEmail = false;
    // this.userTypeSelected = false;
    this.form.reset();
    this.form.markAsPristine();
  }

  toggleShowSpinner(event: boolean): void {
    this.showSpinner = event;
  }

  back() {
    this.navigateService.back();
  }

  setConfirmationData() {
    const confirmData = this.storeService.getAuthCodeValidationData();
    if (confirmData) {
      this.showValidateCode = true;
      this.showSpinner = false;

      this.form.setValue({
        email: confirmData.email,
        password: confirmData.password,
        firstName: '',
        lastName: '',
      });
    }
  }
}
