import { RedirectionLink } from '../models/redirection-link';

// Add here your app route

/**
 * Route for terms and condition screen
 */
export const termsConditionLink: RedirectionLink = {
  icon: '', // not needed for terms condition
  routerPath: '/terms-privacy/terms-condition',
  title: '', // not needed for terms condition
  dataTestID: '',
};

/**
 * Route for privacy and policy screen
 */
export const privacyPolicyLink: RedirectionLink = {
  icon: '', // not needed for privacy policy
  routerPath: '/terms-privacy/privacy-policy',
  title: '', // not needed for privacy policy
  dataTestID: '',
};

/**
 *
 */
export const collaborationAgreementLink: RedirectionLink = {
  icon: '', // not needed for privacy policy
  routerPath: '/terms-privacy/collaboration-agreement',
  title: '', // not needed for privacy policy
  dataTestID: '',
};

export const OFFER_BRAND_CREATION_INFLUENCERS_LINK: RedirectionLink = {
  icon: '', // not needed
  routerPath: 'pages/brand/offers/creation/influencer',
  title: '', // not needed
  dataTestID: '',
};

export const OFFER_BRAND_CREATION_LINK: RedirectionLink = {
  icon: '', // not needed
  routerPath: 'pages/brand/offers/creation/offer',
  title: '', // not needed
  dataTestID: '',
};

export const OFFER_BRAND_CREATION_TASK_LINK: RedirectionLink = {
  icon: '', // not needed
  routerPath: 'pages/brand/offers/creation/task',
  title: '', // not needed
  dataTestID: '',
};

export const OFFER_BRAND_CREATION_PERK_LINK: RedirectionLink = {
  icon: '', // not needed
  routerPath: 'pages/brand/offers/creation/perk',
  title: '', // not needed
  dataTestID: '',
};

export const OFFER_BRAND_CREATION_AGREEMENTS_LINK: RedirectionLink = {
  icon: '', // not needed
  routerPath: 'pages/brand/offers/creation/agreements',
  title: '', // not needed
  dataTestID: '',
};

export const OFFER_BRAND_CREATION_OVERVIEW_LINK: RedirectionLink = {
  icon: '', // not needed
  routerPath: 'pages/brand/offers/creation/overview',
  title: '', // not needed
  dataTestID: '',
};

export const OFFER_BRAND_OVERVIEW_LINK: RedirectionLink = {
  icon: '',
  routerPath: 'pages/brand/offers/overview',
  title: '',
  dataTestID: '',
};

export const DEAL_BRAND_LINK: RedirectionLink = {
  icon: '',
  routerPath: 'pages/brand/deals',
  title: '',
  dataTestID: '',
};

export const OFFER_BRAND_NEGOTIATIONS_LINK: RedirectionLink = {
  icon: '',
  routerPath: 'pages/brand/negotiations/overview',
  title: '',
  dataTestID: '',
};
