import { Injectable, Optional } from '@angular/core';
import { CloudinaryImage, CloudinaryVideo } from '@cloudinary/url-gen';
import { format, quality } from '@cloudinary/url-gen/actions/delivery';
import { scale, thumbnail } from '@cloudinary/url-gen/actions/resize';
import { trim } from '@cloudinary/url-gen/actions/videoEdit';
import { FocusOn } from '@cloudinary/url-gen/qualifiers/focusOn';
import { videoWebm } from '@cloudinary/url-gen/qualifiers/format';
import { focusOn } from '@cloudinary/url-gen/qualifiers/gravity';
import { MediaFormatType } from '../_constants/media-format-type';
import { MediaType } from '../_constants/media-type';
import { v4 as uuidv4 } from 'uuid';

export class MediaLibraryServiceConfig {
  cloud_name = '';
  api_key = '';
  api_upload_endpoint = '';
}

@Injectable({
  providedIn: 'root',
})
export class MediaLibraryService {
  videoFormatTypes = MediaFormatType.VideoFormatType;
  imageFormatTypes = MediaFormatType.ImageFormatType;

  // chunk positions
  start = 0;
  end = 0;

  // chunk size 10mb
  readonly chunkSize = 10000000;

  private _cloud_name = '';
  private _api_key = '';
  private _api_upload_endpoint = '';

  get cloudName() {
    return this._cloud_name;
  }

  get apiKey() {
    return this._api_key;
  }

  get apiUploadEndpoint() {
    return this._api_upload_endpoint;
  }

  constructor(@Optional() config?: MediaLibraryServiceConfig) {
    if (config) {
      this._cloud_name = config.cloud_name;
      this._api_key = config.api_key;
      this._api_upload_endpoint = config.api_upload_endpoint;
    }
  }

  /**
   * upload a file into Cloudinary
   * @param file
   * @param signData
   * @returns
   */
  async upload(
    file: File,
    signData: { signature: string; folder: string; timestamp: string; public_id: string }
  ) {
    return file.size > this.chunkSize
      ? await this.upload_large(file, signData)
      : await this.upload_small(file, signData);
  }

  /**
   * upload a file into cloudinary using the received signature
   * @param file to upload
   * @param signData signature to authenticate the request
   * @returns file uploaded metadata
   */
  async upload_small(
    file: Blob,
    signData: { signature: string; folder: string; timestamp: string; public_id: string }
  ): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('api_key', this._api_key);
    formData.append('signature', signData.signature);
    formData.append('folder', signData.folder);
    formData.append('timestamp', signData.timestamp);
    formData.append('public_id', signData.public_id);

    return fetch(this._api_upload_endpoint, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        return JSON.parse(data);
      });
  }

  /**
   * split file into chunks of ${chunkSize}mb and upload them
   * @param file
   * @param signData
   * @returns
   */
  async upload_large(
    file: File,
    signData: { signature: string; folder: string; timestamp: string; public_id: string }
  ): Promise<any> {
    const uploadId = uuidv4();

    let start = 0;
    let end = 0;
    const size = file.size;
    const chunkLength = Math.ceil(size / this.chunkSize);

    let responseText = '';
    for (let i = 0; i < chunkLength; i++) {
      start = i * this.chunkSize;
      end = start + this.chunkSize < size ? start + this.chunkSize : size;

      const chunk = file.slice(start, end);
      responseText = await this.sendChunk(chunk, signData, start, end - 1, size, uploadId);
    }
    return JSON.parse(responseText);
  }

  /**
   * upload a chunk in to cloudinary
   * @param piece chunk of a file
   * @param signData
   * @param start position
   * @param end position
   * @param size total size of the file
   * @param uploadId unique uploadId
   * @returns
   */
  sendChunk(
    piece: Blob,
    signData: { signature: string; folder: string; timestamp: string; public_id: string },
    start: number,
    end: number,
    size: number,
    uploadId: string
  ): Promise<string> {
    const formData = new FormData();
    formData.append('file', piece);
    formData.append('api_key', this._api_key);
    formData.append('folder', signData.folder);
    formData.append('timestamp', signData.timestamp);
    formData.append('public_id', signData.public_id);
    formData.append('signature', signData.signature);

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', this._api_upload_endpoint, true);
      xhr.setRequestHeader('X-Unique-Upload-Id', uploadId);
      xhr.setRequestHeader('Content-Range', 'bytes ' + start + '-' + end + '/' + size);

      xhr.onload = function () {
        const status = xhr.status;
        if (status == 200) {
          resolve(xhr.responseText);
        } else {
          reject(status);
        }
      };

      xhr.send(formData);
    });
  }

  /**
   * return the type of the uploaded asset
   * @param type
   * @returns
   */
  getTypes(type: string): string {
    if (this.imageFormatTypes.includes(type)) {
      return MediaType.IMAGE;
    }

    if (this.videoFormatTypes.includes(type)) {
      return MediaType.VIDEO;
    }

    return MediaType.UNDEFINED;
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of an image transformed in thumbnail
   */
  async thumbnailPicture(publicId: string): Promise<string> {
    const image = new CloudinaryImage(publicId, {
      cloudName: this._cloud_name,
    }).resize(thumbnail().width(150).height(150).gravity(focusOn(FocusOn.face())));

    return image.toURL();
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of an image resized according to the platform width
   */
  async optimizedPicture(publicId: string) {
    const image = new CloudinaryImage(publicId, {
      cloudName: this._cloud_name,
    }).resize(scale().width(window.innerWidth));

    return image.toURL();
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of the original image
   */
  async originalPicture(publicId: string) {
    const image = new CloudinaryImage(publicId, {
      cloudName: this._cloud_name,
    });

    return image.toURL();
  }

  /**
   *
   * @param publicId public ID on cloudinary
   * @returns the url of the original video
   */
  async originalVideo(publicId: string) {
    const video = new CloudinaryVideo(publicId, {
      cloudName: this._cloud_name,
    });

    return video.toURL();
  }

  /**
   *
   * @param publicId public ID on cloudinary
   * @param duration number of seconds
   * @returns the url of a sampled video
   */
  async sampleDurationVideo(publicId: string, duration: number) {
    const video = new CloudinaryVideo(publicId, { cloudName: this._cloud_name })
      .videoEdit(trim().duration(duration))
      .delivery(format(videoWebm()));

    return video.toURL();
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of the webm video
   */
  async optimizedVideo(publicId: string) {
    const video = new CloudinaryVideo(publicId, {
      cloudName: this._cloud_name,
    }).delivery(format(videoWebm()));

    return video.toURL();
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of the low quality video
   */
  async lowQualityVideo(publicId: string) {
    const video = new CloudinaryVideo(publicId, { cloudName: this._cloud_name })
      .delivery(quality(50))
      .delivery(format(videoWebm()));

    return video.toURL();
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of the video thumbnail
   */
  async thumbnailVideo(publicId: string) {
    const thumbnail = new CloudinaryImage(publicId + '.jpg', {
      cloudName: this._cloud_name,
    }).setAssetType('video');

    return thumbnail.toURL();
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of the original pdf
   */
  async originalPdf(publicId: string) {
    const pdf = new CloudinaryImage(publicId, {
      cloudName: this._cloud_name,
    });

    return pdf.toURL();
  }

  /**
   * @param publicId public ID on cloudinary
   * @returns the url of the pdf thumbnail
   */
  async thumbnailPdf(publicId: string) {
    const thumbnail = new CloudinaryImage(publicId + '.jpg', {
      cloudName: this._cloud_name,
    });

    return thumbnail.toURL();
  }

  /**
   * Get and return the Cloudinary URL of a video delivered in mp4.
   * @param publicId The public ID of the video.
   * @returns The Cloudinary URL of the video delivered in mp4.
   */
  videoMp4(publicId: string) {
    const video = new CloudinaryVideo(`${publicId}.mp4`, {
      cloudName: this._cloud_name,
    });
    return video.toURL();
  }
}
