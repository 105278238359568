import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CoreAuthCognitoGuard } from '@serious-stack/core/auth/angular';
import { UserGuard } from './guards';

const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
    canActivate: [CoreAuthCognitoGuard, UserGuard],
    data: {
      redirectError: '/auth',
    },
  },
  {
    path: 'terms-privacy',
    loadChildren: () =>
      import('./shared/term-conditions/term-conditions-module').then((m) => m.TermConditionModule),
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class KickfloFrontendRoutingModule {}
