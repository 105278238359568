import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { catchError, finalize, map, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { StoreRepository } from '../store';

@Injectable({
  providedIn: 'root',
})
export class UserGuard implements CanActivate {
  constructor(
    private readonly navCtrl: NavController,
    private readonly userService: UserService,
    private readonly storeRepository: StoreRepository
  ) {}

  canActivate() {
    const isUserLoaded = !!this.storeRepository.storeValue.user;

    /**
     * If user is already loader, we can skip the global loading
     * and allow the module/page to be activated.
     *
     * We refresh the user data in the background.
     *
     * NOTE: We might not want this behavior.
     */
    if (isUserLoaded) {
      this.userService.userControllerGetMe().subscribe();
      return true;
    }

    this.storeRepository.store.update((store) => ({
      ...store,
      isLoading: true,
    }));

    return this.userService.userControllerGetMe().pipe(
      map(() => true),
      catchError((error) => {
        console.error(error);
        this.navCtrl.back();
        return of(false);
      }),
      finalize(() => {
        this.storeRepository.store.update((store) => ({
          ...store,
          isLoading: false,
        }));
      })
    );
  }
}
