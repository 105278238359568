<div [class]="customPopoverClass">
  <i-feather
    *ngIf="!svgIcon"
    id="show-popover"
    name="alert-circle"
    class="alert-circle"
    [class.section-icon]="sectionPopOver"
  >
  </i-feather>

  <img
    *ngIf="svgIcon"
    id="show-popover"
    class="alert-circle"
    src="../../../assets/icon/custom/{{ svgIcon }}"
    [class.section-icon]="sectionPopOver"
  />

  <ion-popover
    class="strength-popover"
    trigger="show-popover"
    side="bottom"
    alignment="start"
    [arrow]="sectionPopOver ? false : true"
    size="auto"
    mode="ios"
  >
    <ng-template>
      <ion-content>
        <div class="w-100">
          <p
            *ngFor="let popoverValue of customPopover"
            class="text m-0"
            [class.text-in-line]="popoverValue.text"
            [class]="popoverValue.textClass"
          >
            {{
              popoverValue.textValidator
                ? popoverValue.textValidator
                : (popoverValue.text | translate)
            }}
          </p>
        </div>
      </ion-content>
    </ng-template>
  </ion-popover>
</div>
