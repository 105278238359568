<div>
  <!-- Go Back Header (small screens) -->
  <div class="header-back-container pt-1 d-lg-none">
    <ion-button class="header-back-button mx-0" (click)="handleRedirection()">
      <ion-icon name="chevron-back-outline"></ion-icon>
    </ion-button>
  </div>

  <!-- Title & subtitle -->
  <div class="d-flex flex-column ion-align-items-center ion-justify-content-center w-100 mt-5">
    <!-- Image -->
    <div
      class="rounded-circle box-shadow-xs send-code-email"
      style="background-image: url('{{ 'kickflo/global/240_F_434458319_6acl8HhObhYLlq6JX37VFdRPUFHyI6tF_copie_czaak3' | media: 'image' | async }}')"
    ></div>
    <!-- Title -->
    <h1 class="send-code-header-title ion-text-center mt-3 mb-1 d-block">
      {{ 'resetPassword.checkYourEmail' | translate }}
    </h1>
    <!-- Subtitle -->
    <h3 class="send-code-header-subtitle ion-text-center mx-lg-5 my-0 w-lg-50">
      {{ 'resetPassword.resetPasswordCodeSubtitle' | translate:{ codeLength: codeQuantity } }}
    </h3>
  </div>

  <div class="w-100">
    <!-- Code form -->
    <validation-code
      [invalidCode]="invalidCode"
      [isCodeExpired]="isCodeExpired"
      (codeCompleted)="signUpCode($event)"
      [codeNumberQuantity]="codeQuantity"
      [codeNumberClass]="'code-class'"
    >
    </validation-code>

    <!-- Resend email -->
    <div class="send-code-check-spam ion-text-center mt-lg-4">
      {{ 'resetPassword.didntReceive' | translate }}
      <p class="m-0">
        {{ 'resetPassword.checkYourSpam' | translate }}
        <a class="send-code-resend-email" (click)="sendCodeAgain()">
          {{ 'resetPassword.resendEmail' | translate }}
        </a>
      </p>
    </div>
  </div>
</div>
