import { createState, Store, withProps } from '@ngneat/elf';
import { InjectionToken } from '@angular/core';
import { excludeKeys, localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { RoleName, User } from '@prisma/client';
import { withRequestsStatus } from '@ngneat/elf-requests';

export enum Request {
  ROUTE_ROLE_ACCESS = 'route-role-access',
}

export interface UserType extends User {
  role: RoleName;
  company: string;
  cognitoIdentity: {
    username: string;
    email: string;
    provider: string;
  };
  password?: string;
}

export interface AuthCodeValidationType {
  email: string;
  password: string;
}

export interface StoreProps {
  selectedCompany: string;
  user: UserType;
  isLoading: boolean;
  authCodeValidationData: AuthCodeValidationType;
}

const name = 'store';
const { state, config } = createState(
  withProps<StoreProps>({
    selectedCompany: '',
    isLoading: false,
    user: null as never,
    authCodeValidationData: null as never,
  }),
  withRequestsStatus<Request.ROUTE_ROLE_ACCESS>()
);

const store = new Store({ name, state, config });

persistState(store, {
  key: name,
  storage: localStorageStrategy,
  source: () =>
    store.pipe(excludeKeys(['requestsStatus', 'isLoading', 'user', 'authCodeValidationData'])),
});

export type providerType = typeof store;
export const providerToken = new InjectionToken<providerType>('STORE', {
  providedIn: 'root',
  factory: () => store,
});

export default {
  provide: providerToken,
  useValue: store,
};
