<!-- Reusable Progress Bar Password -->
<div [class]="strengthPasswordProgressBarClass">
  <!-- Without values -->
  <ion-progress-bar
    class="strength-password-bar"
    value="0"
    *ngIf="!passwordValue"
  >
  </ion-progress-bar>
  <div
    class="d-flex ion-align-items-center mb-4 mt-1 strength-password-bar-text"
    *ngIf="!passwordValue"
  >
    <custom-popover [customPopover]="strengthPasswordPopover"> </custom-popover>
    {{ 'resetPassword.passwordStrength' | translate }}
  </div>
  <!-- With values -->
  <div *ngFor="let value of strengthValues; let i = index">
    <ion-progress-bar
      class="strength-password-bar"
      [class.too-weak]="validateStrength().id === 0"
      [class.weak]="validateStrength().id === 1"
      [class.medium]="validateStrength().id === 2"
      [class.strong]="validateStrength().id === 3"
      [value]="value"
      *ngIf="validateStrength().id === i && passwordValue"
    >
    </ion-progress-bar>
  </div>
  <div
    class="ion-align-items-center d-flex strength-password-bar-text mb-4 mt-1"
    *ngIf="passwordValue"
  >
    <custom-popover [customPopover]="strengthPasswordPopover"> </custom-popover>
    <span *ngIf="validateStrength().id === 0">
      {{ 'strengthPassword.weak' | translate }}
    </span>
    <span *ngIf="validateStrength().id === 1">
      {{ 'strengthPassword.medium' | translate }}
    </span>
    <span *ngIf="validateStrength().id === 2">
      {{ 'strengthPassword.strong' | translate }}
    </span>
    <span *ngIf="validateStrength().id === 3">
      {{ 'strengthPassword.veryStrong' | translate }}
    </span>
  </div>
</div>
