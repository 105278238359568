<ion-toolbar class="header-color pt-1" [class]="headerClass">
  <!-- Icon -->
  <ion-buttons slot="start" class="ps-2" *ngIf="headerStatus === 'common-pages'">
    <ion-button class="logo-icon"
    data-testId="modalMenu-button-logo"
    (click)="openMenuCustom()">
      <img src="{{ 'kickflo/global/logo_ha9msb' | media: 'image' | async }}">
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="start" class="ps-2" *ngIf="headerStatus === 'signUp'
          || headerStatus === 'signIn'">
    <ion-button class="logo-icon" (click)="dashboardPage()">
      <img src="{{ 'kickflo/global/dark-picto_q7uqsr' | media: 'image' | async }}">
    </ion-button>
  </ion-buttons>
  <!-- Sign Up -->
  <ion-buttons slot="end" class="pe-2">
    <ion-button *ngIf="headerStatus === 'signUp'" class="header-sign" data-testId="header-button-signIn"
      (click)="signIn()">
      {{ 'signIn.login' | translate }}
    </ion-button>
    <ion-button *ngIf="headerStatus === 'signIn'" class="header-sign" data-testId="header-button-signUp"
      (click)="signUp()">
      {{ 'signUp.signUp' | translate }}
    </ion-button>
  </ion-buttons>
