import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { RoleName } from '@prisma/client';
import { firstValueFrom } from 'rxjs';
import { StoreRepository, Request } from '../store';
import { GlobalService } from "../services/global.service";

@Injectable({
  providedIn: 'root',
})
export class RolesGuard implements CanActivate {
  constructor(
    private readonly storeRepository: StoreRepository,
    private readonly globalService: GlobalService) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    try {
      this.storeRepository.updateRequestStatus(Request.ROUTE_ROLE_ACCESS, 'pending');

      const routeRoles: Array<RoleName> = route.data?.roles;
      const user = await firstValueFrom(this.storeRepository.user$);

      if (!routeRoles.length || (user && routeRoles.includes(user.role))) {
        this.storeRepository.updateRequestStatus(Request.ROUTE_ROLE_ACCESS, 'success');

        return true;
      }

      throw new Error('Forbiddden. User does not have the required role to access route.');
    } catch (error) {
      this.storeRepository.updateRequestStatus(Request.ROUTE_ROLE_ACCESS, 'error', error);
      await this.globalService.goToHome();
      throw error;
    }
  }
}
