import { InjectionToken } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { CoreAuthSettingsRepository } from '../repositories';

export type providerType = typeof Auth;
export const getStorageState = (authSettings: CoreAuthSettingsRepository) => {
  if (authSettings.store$.value.isRemembered) {
    return localStorage;
  }

  return sessionStorage;
};

export const factory = (authSettings: CoreAuthSettingsRepository) => {
  Auth.configure({
    region: process.env.NG_APP_AMPLIFY_REGION as string,
    userPoolId: process.env.NG_APP_AMPLIFY_USER_POOL_ID as string,
    userPoolWebClientId: process.env
      .NG_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID as string,
    authenticationFlowType: process.env
      .NG_APP_AMPLIFY_AUTHENTICATION_FLOW_TYPE as string,
    oauth: {
      domain: process.env.NG_APP_AMPLIFY_OAUTH_DOMAIN as string,
      scope: process.env.NG_APP_AMPLIFY_OAUTH_SCOPE?.split(
        ','
      ) as Array<string>,
      redirectSignIn: process.env
        .NG_APP_AMPLIFY_OAUTH_REDIRECT_SIGNIN as string,
      redirectSignOut: process.env
        .NG_APP_AMPLIFY_OAUTH_REDIRECT_SIGNOUT as string,
      type: process.env.NG_APP_AMPLIFY_OAUTH_TYPE as string,
      responseType: process.env.NG_APP_AMPLIFY_OAUTH_RESPONSE_TYPE as string,
    },
    storage: getStorageState(authSettings),
  });

  return Auth;
};

export const providerToken = new InjectionToken<providerType>(
  'CORE_AUTH_AMPLIFY'
);
export default {
  provide: providerToken,
  useFactory: factory,
  deps: [CoreAuthSettingsRepository],
};
