<div class="custom-toast-class ion-align-items-center
    d-flex flex-row ion-justify-content-between"
    [class]="customToast?.customClass">
  <div class="ion-align-items-center d-flex flex-row
      custom-toast-left-container">
    <i-feather [name]="(customToast?.customIcon) || 'arrow-right'"
        class="alert-circle"></i-feather>
    <p class="custom-toast-text mb-0 mt-0 ms-1">
      {{ (customToast?.customText) || '' | translate }}
      <span>
        {{ customToast?.customEmail}}
      </span>
    </p>
  </div>
  <a class="type-toggle" (click)="toggleShow()">
    <i-feather name="x" class="close-icon">
    </i-feather>
  </a>
</div>