import { InjectionToken } from '@angular/core';
import flagsmith from 'flagsmith';

export type providerType = typeof flagsmith;
export const providerToken = new InjectionToken<providerType>(
  'CORE_FEATURE_FLAG_FLAGSMITH',
  {
    providedIn: 'root',
    factory: () => flagsmith,
  }
);
