import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { CoreAuthConfigProvider } from '../providers';
import { CoreAuthConfig } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class CoreAuthFeatureGuard implements CanActivate {
  constructor(
    @Inject(CoreAuthConfigProvider.providerToken)
    private readonly config: CoreAuthConfigProvider.providerType,
    private readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const feature: keyof CoreAuthConfig['features'] = route.data.feature;

    if (this.config.features[feature]) {
      return true;
    }

    this.router.parseUrl(this.router.url);
    return false;
  }
}
