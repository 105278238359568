import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class NavigateService {
  constructor(private navCtrl: NavController, private router: Router) {}

  async navigateTo(path: string, parameters?: any[], animation?: boolean) {
    const animated = animation !== undefined ? animation : true;
    if (parameters) {
      await this.navCtrl.navigateForward([path, ...parameters], { animated });
    } else {
      await this.navCtrl.navigateForward([path], { animated });
    }
  }

  async replaceBy(path: string, parameters?: string) {
    await this.router.navigateByUrl(path + parameters, { replaceUrl: true });
  }

  setRoot(path: string) {
    this.navCtrl.navigateRoot([path]);
  }

  async back() {
    await this.navCtrl.back();
  }

  async pop() {
    await this.navCtrl.pop();
  }

  async navigateToRoot(path: string) {
    await this.navCtrl.navigateRoot(path);
  }
}
