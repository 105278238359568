import {
  ChangeDetectorRef,
  AfterContentChecked,
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CoreAuthRepository,
  CoreAuthService,
  CoreAuthStoreProvider,
} from '@serious-stack/core/auth/angular';
import { Subscription } from 'rxjs';

import { ConfirmedValidator } from '../../../../providers/custom-validator';
import { CustomToast } from '../../../../models/custom-toast-model.model';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '../../../../services/navigate.service';

@Component({
  selector: 'kickflo-frontend-auth-reset-password',
  templateUrl: './kickflo-frontend-reset-password.page.html',
  styleUrls: ['./kickflo-frontend-reset-password.page.scss'],
})
export class ResetPasswordPage implements OnInit, OnDestroy, AfterContentChecked {
  codeQuantity = 6;

  customToastData: CustomToast = {
    customClass: '',
    customIcon: 'alert-circle',
    customText: '',
    customTime: 1000,
    customEmail: '',
  };

  loadingSubscriptions: Array<Subscription> = [];

  strengthPopoverData: Array<any> = [
    {
      text: 'strengthPassword.strengthText',
      textValidator: '',
      textClass: '',
    },
    {
      text: '',
      textClass: 'strength-text-bold',
      textValidator: this.translateService.instant('form.strengthPassword'),
    },
  ];
  storeValue = this.coreAuthRepository.storeValue;

  resetPasswordForm: FormGroup;
  submitPasswordForm: FormGroup;

  resetPasswordSubmitted = false;
  isCodeCompleted = false;
  isPasswordResettled = false;
  passwordIsStrong = false;
  showSpinner = false;
  showToastAlert = false;
  invalidCode = false;
  showPassword = false;
  showPasswordConfirm = false;

  constructor(
    private authService: CoreAuthService,
    private changeDetector: ChangeDetectorRef,
    private coreAuthRepository: CoreAuthRepository,
    private fb: FormBuilder,
    private navigateService: NavigateService,
    private translateService: TranslateService
  ) {
    this.resetPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
    this.submitPasswordForm = this.fb.group(
      {
        email: new FormControl('', [Validators.required, Validators.email]),
        code: new FormControl('', [
          Validators.required,
          Validators.pattern(/^[0-9]*$/),
          Validators.pattern(/^.{6,}$/), //should change dependint the codeQuantity
        ]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/\d/),
          Validators.pattern(/[A-Z]/),
          Validators.pattern(/[a-z]/),
          Validators.pattern(/\W/),
        ]),
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
      }
    );
  }

  ngOnInit() {
    this.subscribeAuthStatus();
  }

  ngOnDestroy() {
    this.coreAuthRepository.updateRequestStatus(
      CoreAuthStoreProvider.AuthRequest.RESET_PASSWORD,
      'idle'
    );
    this.coreAuthRepository.updateRequestStatus(
      CoreAuthStoreProvider.AuthRequest.SUBMIT_RESET_PASSWORD,
      'idle'
    );

    this.unsubscribeAuthStatus();
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  /**
   * Get email Field
   */
  get getEmail() {
    return this.resetPasswordForm.value.email;
  }

  /**
   * Get password Field
   */
  get getPassword() {
    return this.submitPasswordForm.value.password;
  }

  /**
   * On click Header go back Previous Page
   */
  handleRedirection() {
    if (!this.isPasswordResettled && !this.resetPasswordSubmitted) {
      this.navigateService.back();
    } else if (this.isPasswordResettled && !this.isCodeCompleted) {
      this.isPasswordResettled = false;
    } else if (this.isCodeCompleted) {
      this.isCodeCompleted = false;
    } else if (this.resetPasswordSubmitted) {
      this.resetPasswordSubmitted = false;
    }
  }

  /**
   * Reset Password
   */
  resetPassword() {
    if (!this.resetPasswordForm.valid) return;
    this.customToastData = {
      customClass: 'alert-toast-class',
      customIcon: 'alert-circle',
      customText: 'resetPassword.resendNewCode',
      customTime: 4000,
      customEmail: this.resetPasswordForm.value.email,
    };

    if (this.isPasswordResettled) {
      this.showToastAlert = true;

      setTimeout(() => {
        this.showToastAlert = false;
      }, this.customToastData.customTime);
    }

    return this.authService.resetPassword({
      email: this.resetPasswordForm.get('email')?.value,
    });
  }

  /**
   * Submit Reset Password
   * Code/Email/Password
   */
  submitResetPassword() {
    if (!this.submitPasswordForm.valid) return;

    return this.authService.submitResetPassword({
      email: this.submitPasswordForm.get('email')?.value,
      code: this.submitPasswordForm.get('code')?.value,
      password: this.submitPasswordForm.get('password')?.value,
    });
  }

  /**
   * Set loadingSubscriptions onInit
   * And get logs after submit
   */
  async subscribeAuthStatus() {
    this.setResetPassword();
    this.setSubmitResetPassword();
  }

  /**
   * Set loadingSubscriptions onInit
   * And get logs after submit
   */
  async unsubscribeAuthStatus() {
    this.loadingSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Set Reset Password onInit
   * And get logs after submit
   */
  async setResetPassword() {
    this.loadingSubscriptions.push(
      this.coreAuthRepository
        .selectRequestStatus(CoreAuthStoreProvider.AuthRequest.RESET_PASSWORD)
        .subscribe(({ value: statusState }) => {
          switch (statusState) {
            case 'pending':
              {
                this.showSpinner = true;
              }
              break;
            case 'error':
              {
                this.showSpinner = false;
              }
              break;
            case 'success':
              {
                const currentEmailValue: string = this.resetPasswordForm.get('email')?.value;
                this.submitPasswordForm.setValue({
                  email: currentEmailValue,
                  code: '',
                  password: '',
                  confirmPassword: '',
                });
                this.showSpinner = false;
                this.isPasswordResettled = true;
              }
              break;
          }
        })
    );
  }

  /**
   * Set Submit Reset Password onInit
   * And get logs after submit
   */
  async setSubmitResetPassword() {
    this.loadingSubscriptions.push(
      this.coreAuthRepository
        .selectRequestStatus(CoreAuthStoreProvider.AuthRequest.SUBMIT_RESET_PASSWORD)
        .subscribe((statusState: any) => {
          switch (statusState.value) {
            case 'pending':
              {
                this.showSpinner = true;
              }
              break;
            case 'error':
              {
                if (
                  statusState.error.code === 'CodeMismatchException' ||
                  statusState.error.code === 'ExpiredCodeException'
                ) {
                  this.invalidCode = true;
                  this.isCodeCompleted = false;
                }
                this.showSpinner = false;
              }
              break;
            case 'success':
              {
                this.showSpinner = false;
                this.isCodeCompleted = false;
                this.isPasswordResettled = false;
                this.resetPasswordSubmitted = true;
              }
              break;
          }
        })
    );
  }
  /**
   * Set the Code filled by the User
   * @param event Event emitted on fill input
   */
  setCode(event: any) {
    this.isCodeCompleted = true;
    this.submitPasswordForm.patchValue({
      email: this.getEmail,
      code: event,
    });
  }

  /**
   * Set the Strength Password Completed by the User
   * @param event Event emitted on Password Strong
   */
  setStrengthCompleted(event: any) {
    if (event !== this.passwordIsStrong) {
      this.passwordIsStrong = event;
    }
  }
}
