import { Inject, Injectable, OnDestroy } from '@angular/core';
import { IFlags, IRetrieveInfo } from 'flagsmith';
import { CoreFeatureFlagFlagsmithProvider } from '../providers';
import { CoreFeatureFlagRepository } from '../repositories';

// @TODO Revamp
const NO_AUTHENTICATED_USER = 'no_authenticated_user';
const TIME_LISTENING = 300000; // 5 minutes

let isInitiallyFetched = false;

@Injectable({
  providedIn: 'root',
})
export class CoreFeatureFlagService implements OnDestroy {
  constructor(
    @Inject(CoreFeatureFlagFlagsmithProvider.providerToken)
    private readonly coreFeatureFlagFlagsmith: CoreFeatureFlagFlagsmithProvider.providerType,
    private readonly coreFeatureFlagRepository: CoreFeatureFlagRepository
  ) {
    this.coreFeatureFlagFlagsmith.init({
      environmentID: process.env.NG_APP_FLAG_SMITH_API_KEY as string,
      preventFetch: false,
      onChange: (oldFlags: IFlags, params: IRetrieveInfo) =>
        this.onChange(oldFlags, params),
    });

    this.coreFeatureFlagFlagsmith.startListening(TIME_LISTENING);
  }

  async identify(user: string = NO_AUTHENTICATED_USER): Promise<void> {
    if (user === NO_AUTHENTICATED_USER) {
      isInitiallyFetched = false;
      this.coreFeatureFlagFlagsmith.stopListening();
      this.coreFeatureFlagFlagsmith.logout();
      return;
    }

    isInitiallyFetched = false;
    await this.coreFeatureFlagFlagsmith.identify(user);
    this.coreFeatureFlagFlagsmith.startListening(TIME_LISTENING);
  }

  ngOnDestroy(): void {
    this.coreFeatureFlagFlagsmith.logout();
    this.coreFeatureFlagFlagsmith.stopListening();
    isInitiallyFetched = false;
  }

  protected onChange(oldFlags: IFlags, params: IRetrieveInfo): void {
    if (
      this.isTheFirstFetch(oldFlags, params, isInitiallyFetched) ||
      this.isFlagUpdated(oldFlags, params)
    ) {
      if (!isInitiallyFetched) {
        isInitiallyFetched = true;
      }

      this.coreFeatureFlagRepository.updateFlags(oldFlags);
    }
  }

  private isTheFirstFetch(
    oldFlags: IFlags,
    params: IRetrieveInfo,
    isInitiallyFetched: boolean
  ): boolean {
    if (isInitiallyFetched) {
      return false;
    }

    if (Object.keys(oldFlags).length === 0) {
      return false;
    }

    if (!params.isFromServer) {
      return false;
    }

    return true;
  }

  private isFlagUpdated(oldFlags: IFlags, params: IRetrieveInfo): boolean {
    if (!params.isFromServer) {
      return false;
    }

    if (Object.keys(oldFlags).length === 0) {
      return false;
    }

    if (!params.flagsChanged && !params.traitsChanged) {
      return false;
    }

    return true;
  }
}
