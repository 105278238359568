import { NgModule } from '@angular/core';

import { FeatherModule } from 'angular-feather';
import {
  AlertCircle,
  AlertTriangle,
  ArrowRight,
  Check,
  CheckCircle,
  Clock,
  DollarSign,
  Eye,
  EyeOff,
  FileText,
  Grid,
  Edit3,
  Info,
  Loader,
  List,
  Lock,
  LogOut,
  Mail,
  Search,
  Settings,
  Slash,
  User,
  X,
  Instagram,
  ChevronLeft,
  MessageCircle,
  MessageSquare,
  Download,
  MapPin,
  Camera,
  Layers,
  Upload,
  Globe,
  BarChart2,
  Package,
  ThumbsUp,
  Facebook,
  Twitter,
  Youtube,
  Calendar,
  UploadCloud,
  ChevronDown,
  ChevronsRight,
  RotateCcw,
  Edit2,
  HelpCircle,
  MoreHorizontal,
  Image,
  Plus,
  Hash,
  ChevronRight,
  PlusCircle,
  XCircle,
  Trash2,
  Link,
  Paperclip,
  Send,
  Minus,
  ChevronUp,
  Link2,
  AlertOctagon,
  Edit,
} from 'angular-feather/icons';

// Select some icons (use an object, not an array)
const icons = {
  AlertCircle,
  AlertTriangle,
  ArrowRight,
  Check,
  Clock,
  DollarSign,
  Eye,
  EyeOff,
  FileText,
  Grid,
  Edit3,
  Info,
  Slash,
  Loader,
  List,
  Lock,
  LogOut,
  Mail,
  MapPin,
  User,
  Search,
  Settings,
  X,
  Instagram,
  Facebook,
  Twitter,
  Youtube,
  ChevronLeft,
  ChevronsRight,
  ChevronDown,
  MessageCircle,
  MessageSquare,
  Download,
  CheckCircle,
  Camera,
  Layers,
  Upload,
  Globe,
  BarChart2,
  Package,
  ThumbsUp,
  Calendar,
  UploadCloud,
  RotateCcw,
  Edit2,
  HelpCircle,
  MoreHorizontal,
  Image,
  Plus,
  Hash,
  ChevronRight,
  PlusCircle,
  XCircle,
  Link,
  Trash2,
  Paperclip,
  Send,
  Minus,
  ChevronUp,
  Link2,
  AlertOctagon,
  Edit,
};

@NgModule({
  imports: [FeatherModule.pick(icons)],
  exports: [FeatherModule],
})
export class IconsModule {}
