<!-- Go Back Header -->
<ion-header class="header-back">
  <div class="header-back-container mt-1">
    <ion-button class="header-back-button mx-0"
        (click)="handleRedirection()">
      <ion-icon name="chevron-back-outline"></ion-icon>
    </ion-button>
  </div>
</ion-header>

<ion-content class="reset-password-content">
  <ion-grid class="h-100 p-0">
    <ion-row  class="h-100 m-0">
      <ion-col class="p-0" size="4">
        <sign-in-banner></sign-in-banner>
      </ion-col>

      <!-- #region Step 1 - Send Email -->
      <ion-col *ngIf="!isPasswordResettled && !resetPasswordSubmitted"
          class="d-lg-flex flex-column ion-align-items-center
              ion-justify-content-lg-end
              ion-justify-content-center p-0 h-100"
          size="12" size-lg="8">

        <!-- Title & Subtitle -->
        <ion-col class="ion-align-items-center d-flex
            ion-justify-content-lg-end flex-column mt-4 mt-lg-0 p-0"
            size="12" size-lg="4">
          <h1 class="reset-password-header-title ion-text-center mt-0 mb-1">
            {{ 'resetPassword.resetPassword' | translate | titlecase }}
          </h1>
          <h3 class="reset-password-header-subtitle ion-text-center
              my-0">
            {{ 'resetPassword.resetPasswordSubtitle' | translate }}
          </h3>
        </ion-col>

        <!-- Form -->
        <ion-col class="d-flex flex-column mt-4 p-0"
            size="12" size-lg="6">
          <form class="d-lg-flex flex-column"
              [formGroup]="resetPasswordForm"
              (ngSubmit)="resetPassword()"
              *ngIf="!isPasswordResettled">

            <!-- Reusable Input item - Email Address -->
            <ion-item class="input-item" lines="none">
              <ion-label class="input-title"
                  position="floating">
                {{ 'resetPassword.sendEmailTo' | translate }}
              </ion-label>
              <ion-input class="input-field ps-2 py-1 py-lg-0-5 opacity-100"
                  [class.invalid]="!resetPasswordForm.controls.email.valid
                        && (resetPasswordForm.controls.email.dirty
                        || resetPasswordForm.controls.email.touched)"
                  type="email"
                  formControlName="email"
                  [placeholder]="'forms.email' | translate | titlecase"
                  inputmode="email"
                  data-testId="resetPassword-input-email"
                  autocomplete="email"
                  required="true">
              </ion-input>
            </ion-item>

            <!-- Errors -->
            <div *ngIf="resetPasswordForm.controls.email.invalid
                && (resetPasswordForm.controls.email.dirty
                || resetPasswordForm.controls.email.touched)">
              <!-- Required -->
              <div class="input-error"
                  *ngIf="resetPasswordForm.controls.email.errors?.['required']">
                {{ 'forms.requiredField' | translate }}
              </div>
              <!-- Invalid format -->
              <div class="input-error"
                  *ngIf="resetPasswordForm.controls.email.errors?.['email']">
                {{ 'forms.invalidEmail' | translate }}
              </div>
            </div>

            <!-- Send Email Button -->
            <div class="mt-4 mt-lg-4-5">
              <ion-button
                  class="w-100 primary-button action-button"
                  type="submit"
                  data-testId="reset-password-button-signIn"
                  [disabled]="!resetPasswordForm.valid || showSpinner">
                {{ 'general.sendEmail' | translate | titlecase }}
              </ion-button>
            </div>

            <!-- Cancel Button -->
            <a [routerLink]="['..']"
                class="cancel-button ion-text-center mt-3 d-lg-block d-none">
              {{ 'general.cancel' | translate | titlecase }}
            </a>
          </form>
        </ion-col>
      </ion-col>
      <!-- #endregion -->

      <!-- #region Steps 2-3 - Enter code and new password -->
      <ion-col *ngIf="isPasswordResettled"
          class="d-lg-flex flex-column ion-align-items-center
            ion-justify-content-center p-0 h-100"
            size="12" size-lg="8">

        <!-- Title & Subtitle -->
        <ion-row class="ion-align-items-center ion-justify-content-center w-100">
          <!-- Check your emails (step 2) -->
          <ion-col *ngIf="!isCodeCompleted"
              class="ion-align-items-center d-flex flex-column
                  mt-lg-0 px-3 mt-3 py-0"
              size="12" size-lg="4">
            <div
              class="rounded-circle box-shadow-xs reset-password-email"
              style="background-image: url('{{ 'kickflo/global/240_F_434458319_6acl8HhObhYLlq6JX37VFdRPUFHyI6tF_copie_czaak3' | media: 'image' | async }}')"
            >
            </div>
            <h1 class="reset-password-header-title ion-text-center
                mt-3 mb-1">
              {{ 'resetPassword.checkYourEmail' | translate }}
            </h1>
            <h3 class="reset-password-header-subtitle ion-text-center
                mx-lg-5 my-0">
              {{ 'resetPassword.resetPasswordCodeSubtitle' | translate:{ codeLength: codeQuantity } }}
            </h3>
          </ion-col>

          <!-- New password (step 3) -->
          <ion-col *ngIf="isCodeCompleted"
              class="ion-align-items-center mt-lg-4 px-3 mt-3 p-0"
              size="12" size-lg="4">
            <h1 class="reset-password-header-title ion-text-center
                mb-1 mt-0">
              {{ 'resetPassword.newPassword' | translate | titlecase }}
            </h1>
            <h3 class="reset-password-header-subtitle ion-text-center
                mx-lg-5 my-0">
              {{ 'resetPassword.enterNewPassword' | translate }}
            </h3>
          </ion-col>
        </ion-row>

        <!-- Form -->
        <ion-row class="ion-align-items-center ion-justify-content-center w-100">
          <ion-col class="d-flex flex-column p-0" size="12" size-lg="6">
            <form class="ion-align-items-center d-flex flex-column
                h-100 ion-justify-content-center w-100"
                [formGroup]="submitPasswordForm"
                (ngSubmit)="submitResetPassword()">

              <!-- Enter Code (step 2) -->
              <validation-code
                  *ngIf="!isCodeCompleted"
                  [invalidCode]="invalidCode"
                  (codeCompleted)="setCode($event)"
                  [codeNumberQuantity]="codeQuantity"
                  [codeNumberClass]="'code-class'">
              </validation-code>

              <p *ngIf="!isCodeCompleted"
                  class="reset-password-check-spam ion-text-center">
                  {{ 'resetPassword.didntReceive' | translate }}
                  {{ 'resetPassword.checkYourSpam' | translate }}
                <a class="reset-password-resend-email"
                    (click)="resetPassword()">
                  {{ 'resetPassword.resendEmail' | translate }}
                </a>
              </p>

              <!-- Enter password (step 3) -->
              <div *ngIf="isCodeCompleted" class="mt-4 mt-lg-5 w-100">
                <!-- Reusable Input item - Password -->
                <ion-item class="input-item" lines="none">
                  <ion-label class="input-title"
                      position="floating">
                    {{ 'signIn.password' | translate | titlecase }}
                  </ion-label>
                  <ion-input
                    class="input-field password ps-2 py-1 py-lg-0-5 opacity-100"
                    [class.invalid]="!submitPasswordForm.controls.password.valid
                          && (submitPasswordForm.controls.password.dirty
                          || submitPasswordForm.controls.password.touched)"
                    [type]="showPassword ? 'text' : 'password'"
                    formControlName="password"
                    [placeholder]="'signIn.password' | translate | titlecase"
                    inputmode="password"
                    data-testId="reset-password-input-password"
                    autocomplete="password"
                    required="true"
                  >
                    <div class="password-eye">
                      <i-feather [hidden]="showPassword" name="eye" class="eye-icon"></i-feather>
                      <i-feather [hidden]="!showPassword" name="eye-off" class="eye-icon"></i-feather>
                    </div>
                  </ion-input>
                  <!-- Clickable overlay with transparent background (hack) -->
                  <div class="password-eye-clickable" (click)="showPassword = !showPassword"></div>
                </ion-item>

                <!-- Reusable Input item - Confirm Password -->
                <ion-item class="input-item mt-2" lines="none">
                  <ion-label class="input-title"
                      position="floating">
                    {{ 'resetPassword.confirmPassword' | translate | titlecase }}
                  </ion-label>
                  <ion-input
                    class="input-field password ps-2 py-1 py-lg-0-5 opacity-100"
                    [class.invalid]="!submitPasswordForm.controls.confirmPassword.valid
                          && (submitPasswordForm.controls.confirmPassword.dirty
                          || submitPasswordForm.controls.confirmPassword.touched)"
                    [type]="showPasswordConfirm ? 'text' : 'password'"
                    formControlName="confirmPassword"
                    [placeholder]="'resetPassword.confirmPassword' | translate"
                    inputmode="confirmPassword"
                    data-testId="reset-password-input-password-confirm"
                    autocomplete="password"
                    (click)="$event.stopPropagation()"
                    required="true"
                  >
                    <div class="password-eye">
                      <i-feather [hidden]="showPasswordConfirm" name="eye" class="eye-icon"></i-feather>
                      <i-feather [hidden]="!showPasswordConfirm" name="eye-off" class="eye-icon"></i-feather>
                    </div>
                  </ion-input>
                  <!-- Clickable overlay with transparent background (hack) -->
                  <div class="password-eye-clickable" (click)="showPasswordConfirm = !showPasswordConfirm"></div>
                </ion-item>

                <!-- Password Must Match -->
                <div class="password-must-match mt-1"
                    [class.invalid]="!submitPasswordForm.controls.confirmPassword.valid
                        && (submitPasswordForm.controls.confirmPassword.dirty
                        || submitPasswordForm.controls.confirmPassword.touched)">
                  {{ 'resetPassword.mustMatch' | translate }}
                  <i-feather name="check" class="check-icon"
                      *ngIf="submitPasswordForm.controls.confirmPassword.valid
                          && !submitPasswordForm.controls.confirmPassword.errors?.confirmedValidator">
                  </i-feather>
                </div>

                <!-- Password Strength Bar -->
                <strength-password-progress-bar
                    (strengthCompleted)="setStrengthCompleted($event)"
                    [passwordValue]="getPassword"
                    [strengthPasswordPopover]="strengthPopoverData"
                    [strengthPasswordProgressBarClass]="'strength-password-progress-bar-class'">
                </strength-password-progress-bar>

                <!-- Reset Password Button -->
                <ion-button class="primary-button w-100 action-button"
                    type="submit"
                    [disabled]="!submitPasswordForm.valid || !passwordIsStrong">
                  {{ 'resetPassword.resetPassword' | translate | titlecase }}
                </ion-button>

                <!-- Cancel Button -->
                <a (click)="handleRedirection()"
                    class="cancel-button ion-text-center mt-3 d-lg-block d-none">
                  {{ "general.cancel" | translate | titlecase }}
                </a>
              </div>
            </form>
          </ion-col>
        </ion-row>
      </ion-col>
      <!-- #endregion -->

      <!-- #region Step 4 - Reset Password Done -->
      <ion-col *ngIf="resetPasswordSubmitted"
            class="d-lg-flex flex-column ion-align-items-center ion-justify-content-center p-0 h-100"
            size="12" size-lg="8">
        <ion-row class="ion-align-items-center ion-justify-content-center w-100">
          <ion-col class="ion-align-items-center d-flex flex-column mt-lg-0 px-3 mt-3 py-0"
              size="12" size-lg="6">

            <!-- Image -->
            <div class="reset-password-done-container">
              <div class="ion-align-items-center check-icon-on-image-container
                  d-flex ion-justify-content-center">
                <i-feather name="check" class="check-icon-on-image"
                    *ngIf="submitPasswordForm.controls.confirmPassword.valid
                        && !submitPasswordForm.controls.confirmPassword.errors?.confirmedValidator">
                </i-feather>
              </div>
              <div style="background-image: url('{{'kickflo/global/240_F_408235278_XPAdlygWuFFaTRTFR07qNTytcVq3NRpk_copie_mdvnmx' | media: 'image':  'optimized' | async }}')" class="rounded-circle box-shadow-xs rounded-container"></div>
            </div>

            <!-- Title & Subtitles -->
            <h1 class="reset-password-header-title ion-text-center
                mt-4 mb-1">
              {{ "general.done" | translate | titlecase }}!
            </h1>
            <h3 class="reset-password-header-subtitle font-w-600
                ion-text-center mx-lg-5 my-0">
              {{ "resetPassword.passwordSuccessfullyChanged" | translate }}
            </h3>
            <h3 class="reset-password-header-subtitle ion-text-center
                mx-lg-5 my-0">
              {{ 'resetPassword.pleaseLogin' | translate }}
            </h3>

            <!-- Log In Button -->
            <div class="mt-4 w-100">
              <ion-button class="gray-button w-100 action-button"
                  [routerLink]="['/auth']"
                  type="submit">
                {{ 'signIn.login' | translate }}
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-col>
      <!-- #endregion -->
    </ion-row>
  </ion-grid>
</ion-content>

<custom-toast-alert
    class="custom-toast-alert d-flex ion-justify-content-center p-1 show"
    [customToast]="customToastData"
    (closeEvent)="showToastAlert = $event"
    [class.show]="showToastAlert"
    [class.bottom]="false"
    [class.top]="true">
</custom-toast-alert>

<custom-spinner *ngIf="showSpinner"></custom-spinner>
