import { Injectable } from '@angular/core';
import { StoreRepository } from './store.repository';
import { StoreProps, AuthCodeValidationType } from './store.provider';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  constructor(private readonly storeRepository: StoreRepository) {}

  public updateSelectedCompany(selectedCompany: StoreProps['selectedCompany']) {
    this.storeRepository.store.update((state) => ({
      ...state,
      selectedCompany,
    }));
  }

  public updateAuthCodeValidationData(
    authCodeValidationData: StoreProps['authCodeValidationData']
  ) {
    this.storeRepository.store.update((state) => ({
      ...state,
      authCodeValidationData,
    }));
  }

  public clearAuthCodeValidationData() {
    this.storeRepository.store.update((state) => ({
      ...state,
      authCodeValidationData: null as never,
    }));
  }

  public getAuthCodeValidationData(): AuthCodeValidationType {
    const state = this.storeRepository.store.getValue();
    return state.authCodeValidationData;
  }
}
