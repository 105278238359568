export interface CoreAuthConfig {
  features: {
    signUp: boolean;
    sso: boolean;
    invitation: boolean;
  };
}

export enum AmplifyChallengeName {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
}
