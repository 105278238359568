export class MediaFormatType {
  public static ImageFormatType = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp',
    'bmp',
    'ico',
    'cur',
    'tiff',
    'tif',
  ];
  public static VideoFormatType = [
    'mpg',
    'mpeg',
    'avi',
    'wmv',
    'mov',
    'rm',
    'ram',
    'swf',
    'flv',
    'ogg',
    'webm',
    'mp4',
  ];
}
