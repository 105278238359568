import { Inject, Injectable } from '@angular/core';
import { selectAll, setEntities } from '@ngneat/elf-entities';
import { IFlags, IFlagsmithFeature } from 'flagsmith';
import {
  providerToken as FeatureFlagStoreToken,
  providerType as FeatureFlagStoreType,
  CoreFeatureFlagEntity,
} from '../providers/core-feature-flag-store.provider';

@Injectable({
  providedIn: 'root',
})
export class CoreFeatureFlagRepository {
  constructor(
    @Inject(FeatureFlagStoreToken)
    private readonly coreFeatureFlagStore: FeatureFlagStoreType
  ) {}

  store$ = this.coreFeatureFlagStore.pipe(selectAll());

  updateFlags(flagsParam: IFlags) {
    const flags: IFlagsmithFeature[] = Object.entries(flagsParam).map(
      (flag) => flag[1]
    );

    this.coreFeatureFlagStore.update(
      setEntities(flags as CoreFeatureFlagEntity[])
    );
  }
}
