import { Component, Input } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { GlobalService } from '../../services/global.service';
import { NavigateService } from '../../services/navigate.service';

@Component({
  selector: 'header-icon',
  templateUrl: './header-icon.component.html',
  styleUrls: ['header-icon.component.scss'],
})
export class HeaderIconComponent {
  @Input() headerStatus: string;
  @Input() headerClass: string;
  headerTranslucent = true;

  user: any = {};

  constructor(
    private readonly navigateService: NavigateService,
    private readonly menu: MenuController,
    private readonly globalService: GlobalService,
  ) {
    this.headerStatus = '';
    this.headerClass = '';
    this.menu.enable(false, 'customMenu');
  }

  /**
   * Handle Dashboard redirection
   */
  dashboardPage() {
    if (this.headerStatus !== 'signUp' && this.headerStatus !== 'signIn') {
      this.globalService.goToHome();
    }
  }

  /**
   * Open Left menu
   */
  openMenuCustom() {
    this.menu.enable(true, 'customMenu');
    this.menu.open('customMenu');
  }

  /**
   * Redirects to SignUp
   */
  signUp() {
    this.navigateService.navigateTo('/auth/signup');
  }

  /**
   * Redirects to SignIn
   */
  signIn() {
    this.navigateService.navigateTo('/auth/signin');
  }

  /**
   * Redirects to SignIn
   */
  signOut() {
    return this.globalService.signOut();
  }
}
