import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

//Components
import { HeaderIconComponent } from './header-icon/header-icon.component';
import { TermConditionsComponent } from '../shared/term-conditions/term-conditions.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { CustomSpinnerComponent } from './custom-spinner/custom-spinner.component';
import { ValidationCodeComponent } from './validation-code/validation-code.component';
import { IconsModule } from '../../assets/icons.module';
import { StrengthPasswordProgressBarComponent } from './strength-password-progress-bar/strength-password-progress-bar.component';
import { CustomToastAlertComponent } from './custom-toast-alert/custom-toast-alert.component';
import { CustomPopoverComponent } from './custom-popover/custom-popover.component';
import { CustomMenuComponent } from './custom-menu/custom-menu.component';
import { CustomMenuTabsComponent } from './custom-menu-tabs/custom-menu-tabs';
import { CustomStaticMenuComponent } from './custom-static-menu/custom-static-menu.component';
import { TaskCardComponent } from './task-card/task-card.component';
import { PerkCardComponent } from './perk-card/perk-card.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { LoaderCheckComponent } from './loader-check/loader-check.component';
import { SliderTaskCardComponent } from './slider-task-card/slider-task-card.component';
import { SliderPerkCardComponent } from './slider-perk-card/slider-perk-card.component';
import { MediaLibraryModule } from '@serious-stack/core/media-library/angular';
import { InfoCardComponent } from './info-card/info-card.component';
import { AccordionFieldComponent } from './accordion-field/accordion-field.component';
import { FieldComponent } from './field/field.component';
import { FormsSectionComponent } from './forms-section/forms-section.component';
import { FieldIconComponent } from './field-icon/field-icon.component';
import { FooterComponent } from './footer/footer.component';
import { TopBarInfoComponent } from './top-bar-info/top-bar-info.component';
import { TopBarBrandComponent } from './top-bar-brand/top-bar-brand.component';
import { SignInBannerComponent } from './sign-in-banner/sign-in-banner.component';
import { SsoButtonComponent } from './sso-button/sso-button.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { TaskPaymentCardComponent } from './task-payment-card/task-payment-card.component';
import { TabsBarComponent } from './tabs-bar/tabs-bar.component';
import { TalentComponent } from './talent/talent.component';
import { OfferDetailComponent } from './offer-detail/offer-detail.component';
import { OfferStatusComponent } from './offer-status/offer-status.component';
import { InfoComponent } from './info/info.component';
import { DatatableDetailsComponent } from './datatable-details/datatable-details.component';
import { CommentComponent } from './comment/comment.component';
import { ReplyOfferModalComponent } from './modals/reply-offer-modal/reply-offer-modal.component';
import { DoneDealModalComponent } from './modals/done-deal-modal/done-deal-modal.component';
import { DeclineDealModalComponent } from './modals/decline-deal-modal/decline-deal-modal.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { TodoModalHeaderComponent } from './todo-modal-header/todo-modal-header.component';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { BriefComponent } from './brief/brief.component';
import { ProductsPerksListComponent } from './products-perks-list/products-perks-list.component';
import { ScopeOfWorkListComponent } from './scope-of-work-list/scope-of-work-list.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FieldDateComponent } from './field/field-date/field-date.component';
import { FieldTextComponent } from './field/field-text/field-text.component';
import { FieldCurrencyComponent } from './field/field-currency/field-currency.component';
import { SendCounterModalComponent } from './modals/send-counter-modal/send-counter-modal.component';
import { OfferLaunchedModalComponent } from './modals/offer-launched-modal/offer-launched-modal.component';
import { FieldNumberComponent } from './field/field-number/field-number.component';
import { SlidingBankingInformationComponent } from './sliding-banking-information/sliding-banking-information.component';
import { FilePreviewModalComponent } from './file-preview-modal/file-preview-modal.component';

@NgModule({
  declarations: [
    CustomPopoverComponent,
    CustomSpinnerComponent,
    CustomToastAlertComponent,
    HeaderIconComponent,
    ShowHidePasswordComponent,
    StrengthPasswordProgressBarComponent,
    TermConditionsComponent,
    ValidationCodeComponent,
    CustomMenuComponent,
    CustomMenuTabsComponent,
    CustomStaticMenuComponent,
    TaskCardComponent,
    PerkCardComponent,
    TopBarComponent,
    EmptyStateComponent,
    LoaderCheckComponent,
    SliderTaskCardComponent,
    SliderPerkCardComponent,
    InfoCardComponent,
    AccordionFieldComponent,
    FieldComponent,
    FormsSectionComponent,
    FieldIconComponent,
    FooterComponent,
    TopBarInfoComponent,
    TopBarBrandComponent,
    SignInBannerComponent,
    SsoButtonComponent,
    ProfilePictureComponent,
    TaskPaymentCardComponent,
    TabsBarComponent,
    TalentComponent,
    OfferDetailComponent,
    OfferStatusComponent,
    InfoComponent,
    DatatableDetailsComponent,
    CommentComponent,
    ReplyOfferModalComponent,
    DoneDealModalComponent,
    DeclineDealModalComponent,
    PaymentStatusComponent,
    TodoModalHeaderComponent,
    PaymentModalComponent,
    BriefComponent,
    ProductsPerksListComponent,
    ScopeOfWorkListComponent,
    FieldDateComponent,
    FieldTextComponent,
    FieldCurrencyComponent,
    SendCounterModalComponent,
    OfferLaunchedModalComponent,
    FieldNumberComponent,
    SlidingBankingInformationComponent,
    FilePreviewModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconsModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    MediaLibraryModule,
    NgxDatatableModule,
  ],
  exports: [
    CustomPopoverComponent,
    CustomSpinnerComponent,
    CustomToastAlertComponent,
    HeaderIconComponent,
    ShowHidePasswordComponent,
    StrengthPasswordProgressBarComponent,
    TermConditionsComponent,
    ValidationCodeComponent,
    CustomMenuComponent,
    CustomMenuTabsComponent,
    CustomStaticMenuComponent,
    TaskCardComponent,
    PerkCardComponent,
    TopBarComponent,
    LoaderCheckComponent,
    EmptyStateComponent,
    SliderTaskCardComponent,
    SliderPerkCardComponent,
    InfoCardComponent,
    AccordionFieldComponent,
    FieldComponent,
    FormsSectionComponent,
    FieldIconComponent,
    FooterComponent,
    TopBarInfoComponent,
    SignInBannerComponent,
    SsoButtonComponent,
    ProfilePictureComponent,
    TaskPaymentCardComponent,
    TabsBarComponent,
    TalentComponent,
    OfferDetailComponent,
    OfferStatusComponent,
    InfoComponent,
    DatatableDetailsComponent,
    CommentComponent,
    ReplyOfferModalComponent,
    DoneDealModalComponent,
    DeclineDealModalComponent,
    PaymentStatusComponent,
    TodoModalHeaderComponent,
    PaymentModalComponent,
    BriefComponent,
    ProductsPerksListComponent,
    ScopeOfWorkListComponent,
    NgxDatatableModule,
    FieldCurrencyComponent,
    SendCounterModalComponent,
    OfferLaunchedModalComponent,
    FieldNumberComponent,
    SlidingBankingInformationComponent,
    FilePreviewModalComponent,
  ],
})
export class ReusableComponentsModule {}
