import { Injectable } from '@angular/core';
import { CoreAuthService, CoreAuthSettingsRepository } from '@serious-stack/core/auth/angular';
import { StoreRepository } from '../store';
import { NavigateService } from './navigate.service';
import { Storage } from '@ionic/storage';
import { firstValueFrom } from 'rxjs';
import { RoleName } from '@prisma/client';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  constructor(
    private readonly coreAuthService: CoreAuthService,
    private readonly coreAuthSettingsRepository: CoreAuthSettingsRepository,
    private readonly storeRepository: StoreRepository,
    private readonly storage: Storage,
    private readonly navigateService: NavigateService
  ) {}

  /**
   * Skeleton sequence
   * @param n Quantity of skeletons
   */
  numSequence(n: number): Array<number> {
    return Array(n);
  }

  /**
   * Convert a byte to kilobytes
   * @param byteToConvert is the byte number to convert
   * @returns the kilobyte conversion
   */
  byteToKiloBytes(byteToConvert: number): number {
    return Math.round(byteToConvert / 1024);
  }

  async goToHome() {
    const user = await firstValueFrom(this.storeRepository.user$);

    switch (user.role) {
      case RoleName.accounting:
        this.navigateService.navigateToRoot('pages/accounting/payment');
        break;
      case RoleName.super_admin:
        this.navigateService.navigateToRoot('pages/admin/payment');
        break;
      case RoleName.brand:
        this.navigateService.navigateToRoot('pages/brand/offers');
        break;
      case RoleName.influencer:
        this.navigateService.navigateToRoot('pages/influencer/dashboard');
        break;
    }
  }

  async signOut() {
    await this.coreAuthService.signOut();
    this.storage.clear();
    this.storeRepository.reset();
    this.coreAuthSettingsRepository.reset();
    this.navigateService.navigateToRoot('auth');
  }
}
