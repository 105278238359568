import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { RoleName } from '@prisma/client';
import { User } from '@sentry/angular';
import { Observable, tap } from 'rxjs';
import { StoreRepository } from '../store';
import { BASE_PATH, Configuration } from '../_generated';
import { UserService as GeneratedUserService } from '../_generated/api/user.service';
import { UserDto } from '../_generated/model/userDto';

@Injectable({
  providedIn: 'root',
})
export class UserService extends GeneratedUserService {
  public constructor(
    private readonly _storeRepository: StoreRepository,
    private readonly _httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) private _basePath: string,
    @Optional() private _configuration: Configuration
  ) {
    super(_httpClient, _basePath, _configuration);
  }

  /**
   * Overriding the generated `userControllerGetMe` to automatially
   * update the store `user` property with the return.
   */
  override userControllerGetMe(): Observable<any> {
    return super.userControllerGetMe().pipe(
      tap((user) => {
        this._storeRepository.store.update((store) => ({
          ...store,
          user: {
            ...store?.user,
            ...user,
          } as any,
        }));
      })
    );
  }

  /**
   * Overriding the generated `userControllerUpdate` to automatially
   * update the store `user` property with the updated fields.
   */
  override userControllerUpdate(body: UserDto): Observable<any> {
    return super.userControllerUpdate(body).pipe(
      tap(() => {
        this._storeRepository.store.update((store) => ({
          ...store,
          user: {
            ...store?.user,
            ...body,
          } as any,
        }));
      })
    );
  }
}
