import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ResetPasswordPage, SignInPage, SignUpPage } from './pages';
import { CoreAuthModule } from '@serious-stack/core/auth/angular';
import { environment } from '../../../environments/environment';
import { RouterModule } from '@angular/router';
import { KickfloFrontendAuthRoutingModule } from './kickflo-frontend-auth-routing.module';

import { ReusableComponentsModule } from '../../components/reusable-components.module';
import { IconsModule } from '../../../assets/icons.module';
import { SignupValidateCodeComponent } from './pages/signup-validate-code/kickflo-signup-validate-code.page';
import { MediaLibraryModule } from '@serious-stack/core/media-library/angular';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot({ animated: false }),
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    CoreAuthModule.withConfig(environment),
    KickfloFrontendAuthRoutingModule,
    ReusableComponentsModule,
    FormsModule,
    IconsModule,
    RouterModule,
    MediaLibraryModule,
  ],
  declarations: [SignInPage, SignUpPage, ResetPasswordPage, SignupValidateCodeComponent],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
  ],
})
export class KickfloFrontendAuthModule {}
