<!-- #region Header (smaller screens) -->
<ion-header
  *ngIf="!showValidateCode"
  class="ion-no-border d-flex d-lg-none header-custom"
>
  <ion-toolbar class="header-color pt-1">
    <header-icon
      class="kickflo-header d-lg-none d-flex ion-justify-content-between"
      [headerStatus]="headerStatus"
    >
    </header-icon>
    <div
      class="ion-align-items-center d-flex flex-column w-100 px-lg-4 px-3"
      *ngIf="userTypeSelected"
    >
      <div
        class="ion-align-items-center ion-align-items-lg-start mt-4 d-flex flex-column"
        size-lg="8"
      >
        <div
          class="sign-up-header-title signup text-center mb-1"
          [ngClass]="signUpEmail
                  ? 'show'
                  : 'hide'"
        >
          {{ 'signUp.signUp' | translate }}
        </div>
        <div
          class="sign-up-header-title mb-1"
          [ngClass]="!signUpEmail
                  ? 'show'
                  : 'hide'"
        >
          {{ 'signUp.createAccount' | translate }}
        </div>
        <div
          class="sign-up-header-subtitle"
          *ngIf="signUpEmail"
        >
          {{ 'signUp.signUpSubtitle' | translate }}
        </div>
        <div
          class="sign-up-header-subtitle"
          *ngIf="!signUpEmail"
        >
          {{ 'signUp.signUpSubtitleCreateAccount' | translate }}
        </div>
      </div>
    </div>
  </ion-toolbar>
</ion-header>
<!-- #endregion -->

<!-- #region Content -->
<ion-content class="sign-up-content d-flex">
  <ion-grid class="h-lg-100 p-0">
    <ion-row class="h-lg-100 m-0">
      <ion-col
        class="p-0"
        size="4"
      >
        <sign-in-banner></sign-in-banner>
      </ion-col>

      <ion-col
        *ngIf="!showValidateCode"
        class="d-lg-flex flex-column ion-align-items-center h-lg-100 p-0"
        size="12"
        size-lg="8"
      >
        <!-- Top right - Log in (larger screens) -->
        <div class="sign-up-account d-none d-lg-flex ion-justify-content-end w-100 mt-3">
          {{ 'signUp.alreadyHaveAccount' | translate }}
          <a
            class="ms-1 sign-up-login sign-up-select-user"
            (click)="back()"
          >
            {{ 'signIn.login' | translate }}
          </a>
        </div>

        <!-- #region Step 1 - User type selection -->
        <div
          *ngIf="!userTypeSelected"
          class="ion-justify-content-lg-center h-100 ion-align-items-center d-flex flex-column"
        >
          <!-- Title -->
          <h2 class="sign-up-user-select-title">{{ 'signUp.chooseUserTitle' | translate }}</h2>
          <!-- Creator -->
          <div
            (click)="changeUserSelection('influencer')"
            class="d-flex flex-column ion-align-items-center ion-justify-content-center sign-up-select-user"
          >
            <div
              style="background-image: url('{{'kickflo/global/240_F_436772282_01y9q5XPjS1fRd7xYqqmjXLa23I0KyVB_dptkkt' | media: 'image': 'optimized' | async}}')"
              class="rounded-container box-shadow-xs"
            ></div>
            <div
              class="d-flex flex-column ion-align-items-center ion-justify-content-center w-100 mt-2"
            >
              <div
                class="d-flex ion-align-items-center sign-up-select-type-user"
                data-testId="signUp-button-iAmCreator"
              >
                <span> {{ 'signUp.iAmCreator' | translate }} </span>
                <div
                  class="arrow-right-content d-flex ion-align-items-center ion-justify-content-center"
                >
                  <i-feather
                    name="arrow-right"
                    class="arrow-right"
                  >
                  </i-feather>
                </div>
              </div>
              <div class="sign-up-select-type-user-subtitle">
                {{ 'signUp.iAmCreatorSubtitle' | translate }}
              </div>
            </div>
          </div>
          <!-- Divisor -->
          <div
            class="ion-align-items-center d-flex w-100 flex-row ion-justify-content-center my-5 my-lg-6"
          >
            <span class="divisor-line"></span>
            <p class="my-0 mx-2 divisor-text">{{ 'general.or' | translate | uppercase }}</p>
            <span class="divisor-line"></span>
          </div>
          <!-- Brand -->
          <div
            class="d-flex flex-column ion-align-items-center ion-justify-content-center sign-up-select-user"
          >
            <div
              style="background-image: url('{{'kickflo/global/240_F_428909935_ksAJ9UjFoa8Xlszj0KCT9U8qaKHWbrlC_ukrntm' | media: 'image': 'optimized' | async}}')"
              class="rounded-container box-shadow-xs"
            ></div>
            <div
              class="d-flex flex-column ion-align-items-center ion-justify-content-center w-100 mt-2"
            >
              <div
                class="d-flex ion-align-items-center sign-up-select-type-user"
                data-testId="signUp-button-iAmBrand"
              >
                <span> {{ 'signUp.iAmBrand' | translate }} </span>
                <div
                  class="arrow-right-content d-flex ion-align-items-center ion-justify-content-center"
                >
                  <i-feather
                    name="arrow-right"
                    class="arrow-right"
                  >
                  </i-feather>
                </div>
              </div>
              <div class="sign-up-select-type-user-subtitle">
                {{ 'signUp.iAmBrandSubtitle' | translate }}
              </div>
            </div>
          </div>
        </div>
        <!-- #endregion -->

        <!-- #region Step 2 - Create account -->
        <div
          *ngIf="userTypeSelected"
          class="ion-justify-content-lg-center h-100 ion-align-items-center w-lg-50 d-flex flex-column"
        >
          <!-- Title (large screens) -->
          <div
            class="ion-align-items-center d-none d-lg-flex ion-align-items-lg-start flex-column w-100 px-3 px-lg-4"
          >
            <div
              class="ion-align-items-center ion-align-items-lg-start mt-4 mb-1 d-lg-flex flex-column d-none"
              size-lg="8"
            >
              <div class="sign-up-header-title mb-1">
                {{ 'signUp.createYourAccount' | translate }}
              </div>
              <div class="sign-up-header-subtitle">{{ 'signUp.signUpSubtitle' | translate }}</div>
            </div>
          </div>

          <div
            class="d-lg-flex flex-column ion-align-items-center sign-up-container py-0 px-0 px-lg-3 w-100"
          >
            <div class="d-flex flex-column mt-lg-3 sign-up-container-scroll w-100">
              <!-- #region SSO buttons -->
              <div
                class="d-lg-flex flex-column flex-lg-row mt-5 my-lg-0"
                [ngClass]="!signUpEmail && isSSOActivated
                      ? 'd-flex'
                      : 'd-none'"
              >
                <!-- Continue with email (smaller screens) -->
                <sso-button
                  class="d-lg-none"
                  imageSrc="assets/icon/custom/email-icon.svg"
                  [text]="'signIn.signInToYourEmail' | translate"
                  testId="login-button-withEmail"
                  (btnClick)="signUpEmail = true"
                >
                </sso-button>
                <!-- Continue with Facebook -->
                <sso-button
                  class="my-2 my-lg-0 me-lg-2"
                  [imageSrc]="'kickflo/global/facebook_xava0y' | media: 'image' | async"
                  [text]="'signIn.signInWithFacebook' | translate"
                  testId="sign-up-button-withFacebook"
                  (btnClick)="signUpWithSSO('facebook')"
                >
                </sso-button>
                <!-- Continue with Instagram -->
                <sso-button
                  class="my-lg-0"
                  [imageSrc]="'kickflo/global/instagram_rphkqc' | media: 'image' | async"
                  [text]="'signIn.signInWithInstagram' | translate"
                  testId="sign-up-button-withInstagram"
                  [isMinimal]="true"
                  (btnClick)="signUpWithSSO('google')"
                >
                </sso-button>
              </div>
              <!-- #endregion -->

              <!-- Divisor -->
              <div
                class="ion-align-items-center d-lg-flex d-none flex-row ion-justify-content-center mt-2 mb-1 my-lg-5 w-100"
              >
                <span class="divisor-line"></span>
                <p class="my-0 mx-2 divisor-text">{{ 'general.or' | translate | uppercase }}</p>
                <span class="divisor-line"></span>
              </div>

              <!-- #region Create user form -->
              <form
                class="form-content d-lg-flex flex-column mt-4 mt-lg-0"
                [ngClass]="signUpEmail ? 'd-flex' : 'd-none'"
                id="test"
                [formGroup]="form"
              >
                <div class="form-content-scroll">
                  <div class="d-flex flex-lg-row flex-column ion-align-center w-100">
                    <!-- #region Reusable Input item - First Name -->
                    <div class="d-flex flex-column w-100">
                      <!-- Item -->
                      <ion-item
                        class="input-item w-100 pe-lg-1"
                        lines="none"
                      >
                        <ion-label
                          class="input-title"
                          position="floating"
                        >
                          {{ 'forms.firstName' | translate | titlecase }}
                        </ion-label>
                        <ion-input
                          class="input-field ps-2 py-1 py-lg-0-5 opacity-100"
                          [class.invalid]="!form.controls.firstName.valid
                                  && (form.controls.firstName.dirty
                                  || form.controls.firstName.touched)"
                          type="text"
                          formControlName="firstName"
                          [placeholder]="'forms.firstName' | translate | titlecase"
                          inputmode="firstName"
                          data-testId="signup-input-firstName"
                          autocomplete="firstName"
                          required="true"
                        >
                        </ion-input>
                      </ion-item>
                      <!-- Errors -->
                      <div
                        class="w-100"
                        *ngIf="form.controls.firstName.invalid
                              && (form.controls.firstName.dirty || form.controls.firstName.touched)"
                      >
                        <div
                          class="input-error"
                          *ngIf="form.controls.firstName.errors?.['required']"
                        >
                          {{ 'forms.requiredField' | translate }}
                        </div>
                      </div>
                    </div>
                    <!-- #endregion -->

                    <!-- #region Reusable Input item - Last Name -->
                    <div class="d-flex flex-column w-100">
                      <!-- Item -->
                      <ion-item
                        class="input-item mt-2 mt-lg-0 w-100 ps-lg-1"
                        lines="none"
                      >
                        <ion-label
                          class="input-title"
                          position="floating"
                        >
                          {{ 'forms.lastName' | translate | titlecase }}
                        </ion-label>
                        <ion-input
                          class="input-field ps-2 py-1 py-lg-0-5 opacity-100"
                          [class.invalid]="!form.controls.lastName.valid
                                  && (form.controls.lastName.dirty
                                  || form.controls.lastName.touched)"
                          type="text"
                          formControlName="lastName"
                          [placeholder]="'forms.lastName' | translate | titlecase"
                          inputmode="lastName"
                          data-testId="signup-input-lastName"
                          autocomplete="lastName"
                          required="true"
                        >
                        </ion-input>
                      </ion-item>
                      <!-- Errors -->
                      <div
                        class="w-100"
                        *ngIf="form.controls.lastName.invalid
                              && (form.controls.lastName.dirty || form.controls.lastName.touched)"
                      >
                        <div
                          class="input-error"
                          *ngIf="form.controls.lastName.errors?.['required']"
                        >
                          {{ 'forms.requiredField' | translate }}
                        </div>
                      </div>
                    </div>
                    <!-- #endregion -->
                  </div>

                  <!-- #region Reusable Input item - Email Address -->
                  <ion-item
                    class="input-item mt-2"
                    lines="none"
                  >
                    <ion-label
                      class="input-title"
                      position="floating"
                    >
                      {{ 'forms.email' | translate | titlecase }}
                    </ion-label>
                    <ion-input
                      class="input-field ps-2 py-1 py-lg-0-5 opacity-100"
                      [class.invalid]="!form.controls.email.valid
                            && (form.controls.email.dirty
                            || form.controls.email.touched)
                            || emailAlreadyUsed"
                      type="email"
                      (ionChange)="emailAlreadyUsed = false"
                      formControlName="email"
                      [placeholder]="'forms.email' | translate | titlecase"
                      inputmode="email"
                      data-testId="login-input-email"
                      autocomplete="email"
                      required="true"
                    >
                    </ion-input>
                  </ion-item>
                  <!-- Errors -->
                  <div
                    *ngIf="form.controls.email.invalid
                      && (form.controls.email.dirty || form.controls.email.touched)"
                  >
                    <!-- Required -->
                    <div
                      class="input-error"
                      *ngIf="form.controls.email.errors?.['required']"
                    >
                      {{ 'forms.requiredField' | translate }}
                    </div>
                    <!-- Invalid format -->
                    <div
                      class="input-error"
                      data-testId="signup-lbl-invalidEmailError"
                      *ngIf="form.controls.email.errors?.['email']"
                    >
                      {{ 'forms.invalidEmail' | translate }}
                    </div>
                  </div>
                  <!-- Email already used -->
                  <div
                    class="input-error"
                    data-testId="signup-lbl-EmailAlreadyExistError"
                    *ngIf="emailAlreadyUsed"
                  >
                    {{ 'forms.emailAlreadyExists' | translate }}
                  </div>
                  <!-- #endregion -->

                  <!-- #region Reusable Input item -- Password - From-->
                  <ion-item
                    class="input-item mt-2"
                    lines="none"
                  >
                    <ion-label
                      class="input-title"
                      position="floating"
                    >
                      {{ 'signIn.password' | translate | titlecase }}
                    </ion-label>
                    <ion-input
                      class="input-field password ps-2 py-1 py-lg-0-5 opacity-100"
                      [class.invalid]="!form.controls.password.valid
                        && (form.controls.password.dirty || form.controls.password.touched)"
                      [type]="showPassword ? 'text' : 'password'"
                      formControlName="password"
                      [placeholder]="'signIn.password' | translate | titlecase"
                      inputmode="password"
                      autocomplete="password"
                      required="true"
                      data-testId="signup-input-password"
                    >
                      <div class="password-eye">
                        <i-feather
                          [hidden]="showPassword"
                          name="eye"
                          class="eye-icon"
                        ></i-feather>
                        <i-feather
                          [hidden]="!showPassword"
                          name="eye-off"
                          class="eye-icon"
                        ></i-feather>
                      </div>
                    </ion-input>
                    <!-- Clickable overlay with transparent background (hack) -->
                    <div
                      class="password-eye-clickable"
                      (click)="showPassword = !showPassword"
                    ></div>
                  </ion-item>
                  <!-- Errors -->
                  <div
                    *ngIf="form.controls.password.invalid
                    && (form.controls.password.dirty || form.controls.password.touched)"
                  >
                    <!-- Required -->
                    <div
                      class="input-error"
                      *ngIf="form.controls.password.errors?.['required']"
                    >
                      {{ 'forms.requiredField' | translate }}
                    </div>
                    <!-- Invalid password -->
                    <div
                      class="input-error"
                      *ngIf="invalidPassword"
                    >
                      {{ 'forms.invalidPassword' | translate }}
                    </div>
                  </div>
                  <!-- #endregion -->

                  <strength-password-progress-bar
                    *ngIf="getPassword !== '' && getPassword !== null"
                    (strengthCompleted)="setStrengthCompleted($event)"
                    [passwordValue]="getPassword"
                    [strengthPasswordPopover]="strengthPopoverData"
                    [strengthPasswordProgressBarClass]="'strength-password-progress-bar-class'"
                  >
                  </strength-password-progress-bar>

                  <!-- Create Account button (larger screens) -->
                  <div class="mt-lg-5 w-100 d-none d-lg-block">
                    <ion-button
                      class="w-100 primary-button sign-up-create-button m-0"
                      (click)="signUp()"
                      [disabled]="!form.valid || showSpinner || !passwordIsStrong"
                      data-testId="signup-button-signUp"
                    >
                      {{ 'signUp.createAccount' | translate }}
                    </ion-button>
                  </div>
                </div>
              </form>
              <!-- #endregion -->
            </div>

            <!-- Terms and conditions (larger screens) -->
            <terms-conditions
              class="d-none d-lg-block mt-3"
              [globalTermConditionClass]="'ion-text-left'"
              [customTermsConditions]="customTermsConditionsData"
            >
            </terms-conditions>
          </div>
        </div>
        <!-- #endregion -->
      </ion-col>

      <!-- #region Step 3 - Enter code -->
      <ion-col
        *ngIf="showValidateCode"
        class="d-lg-flex flex-column ion-align-items-center h-100 py-0 px-0"
        size="12"
        size-lg="8"
      >
        <signup-validate-code
          class="d-flex flex-column h-100 ion-align-items-lg-center ion-justify-content-lg-center"
          [user]="form.controls.email.value"
          [password]="form.controls.password.value"
          (everythingCompleted)="signUpCompleted()"
          (goBack)="showForm()"
          (sendCode)="sendCodeAgain($event)"
          (toggleShowSpinner)="toggleShowSpinner($event)"
        >
        </signup-validate-code>
      </ion-col>
      <!-- #endregion -->
    </ion-row>
  </ion-grid>
  <!-- #region Footer (smaller screens) -->
  <ion-footer
    *ngIf="!showValidateCode && userTypeSelected"
    class="ion-no-border mt-2 mb-3 footer-global d-lg-none"
    [class.mb-5]="!signUpEmail"
  >
    <ion-toolbar>
      <!-- Create Account button -->
      <ion-button
        *ngIf="signUpEmail"
        class="w-100 primary-button m-0 sign-up-create-button"
        (click)="signUp()"
        data-testId="signup-button-signUp"
        [disabled]="!form.valid || showSpinner || !passwordIsStrong"
      >
        {{ 'signUp.createAccount' | translate }}
      </ion-button>
      <terms-conditions
        *ngIf="!signUpEmail"
        [class.mt-3]="signUpEmail"
        [globalTermConditionClass]="'ion-text-center'"
        [customTermsConditions]="customTermsConditionsData"
        class="d-block d-lg-none"
      >
      </terms-conditions>
      <terms-conditions
        *ngIf="signUpEmail"
        [class.mt-3]="signUpEmail"
        [globalTermConditionClass]="'ion-text-center'"
        [customTermsConditions]="customTermsConditionsDataAfter"
        class="d-block d-lg-none"
      >
      </terms-conditions>
    </ion-toolbar>
  </ion-footer>
  <!-- #endregion -->
</ion-content>
<!-- #endregion -->

<custom-toast-alert
  class="custom-toast-alert d-flex ion-justify-content-center p-1 show"
  [customToast]="customToastData"
  (closeEvent)="showToastAlert = $event"
  [class.show]="showToastAlert"
  [class.bottom]="false"
  [class.top]="true"
>
</custom-toast-alert>

<custom-spinner *ngIf="showSpinner"></custom-spinner>
