import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { first, mergeMap } from 'rxjs';
import { StoreRepository } from '../store';

@Injectable()
export class CompanyInterceptor implements HttpInterceptor {
  constructor(private readonly store: StoreRepository) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (request.url.includes('i18n')) {
      return next.handle(request);
    }

    return this.store.selectedCompany$.pipe(first()).pipe(
      mergeMap((selectedCompany: string) => {
        request = request.clone({
          setHeaders: {
            [process.env.NG_APP_REQUEST_HEADER_COMPANY_ID as string]: selectedCompany,
          },
        });

        return next.handle(request);
      })
    );
  }
}
