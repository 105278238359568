import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CoreAuthFeatureGuard, CoreAuthConfig } from '@serious-stack/core/auth/angular';
import { GuestGuard } from '../../guards';
import { ResetPasswordPage, SignInPage, SignUpPage } from './pages';

const routes: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'signin',
        component: SignInPage,
        data: { animation: true },
        canActivate: [GuestGuard],
      },
      {
        path: 'signup',
        component: SignUpPage,
        canActivate: [CoreAuthFeatureGuard, GuestGuard],
        data: { feature: 'signUp', animation: true } as {
          feature: keyof CoreAuthConfig['features'];
        },
      },
      {
        path: 'reset-password',
        component: ResetPasswordPage,
      },
      {
        path: 'facebook',
        redirectTo: '/dashboard',
      },
      {
        path: '**',
        redirectTo: 'signin',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class KickfloFrontendAuthRoutingModule {}
