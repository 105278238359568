import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CoreAuthAmplifyProvider } from '@serious-stack/core/auth/angular';
import { catchError, finalize, from, map, mergeMap, of } from 'rxjs';
import { GlobalService } from '../services/global.service';
import { UserService } from '../services/user.service';
import { StoreRepository } from '../store';

@Injectable({
  providedIn: 'root',
})
export class GuestGuard implements CanActivate {
  constructor(
    @Inject(CoreAuthAmplifyProvider.providerToken)
    private readonly coreAuthAmplify: CoreAuthAmplifyProvider.providerType,
    private readonly userService: UserService,
    private readonly storeRepository: StoreRepository,
    private readonly globalService: GlobalService
  ) {}

  canActivate() {
    this.storeRepository.store.update((store) => ({
      ...store,
      isLoading: true,
    }));

    return from(this.coreAuthAmplify.currentSession()).pipe(
      mergeMap(() => this.userService.userControllerGetMe()),
      map(() => {
        this.globalService.goToHome();
        return false;
      }),
      catchError(() => {
        return of(true);
      }),
      finalize(() => {
        this.storeRepository.store.update((store) => ({
          ...store,
          isLoading: false,
        }));
      })
    );
  }
}
