import { OnInit, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import {
  CoreAuthSettingsStoreProvider,
  CoreAuthConfigProvider,
  CoreAuthService,
  CoreAuthSettingsRepository,
} from '@serious-stack/core/auth/angular';
import { NavigateService } from '../../../../services/navigate.service';
import { GlobalService } from '../../../../services/global.service';
import { StoreService } from '../../../../store/store.service';
import { UserService } from '../../../../services/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'kickflo-frontend-stack-signin',
  templateUrl: './kickflo-frontend-signin.page.html',
  styleUrls: ['./kickflo-frontend-signin.page.scss'],
})
export class SignInPage implements OnInit, OnDestroy {
  currentLang: CoreAuthSettingsStoreProvider.AvailableLanguages =
    this.coreAuthSettingsRepository?.storeValue?.currentLanguage;
  customTermsConditionsData: Array<any> = [
    {
      text: 'signIn.signInTermsConditions',
      textClass: '',
      inputType: 'text',
    },
    {
      text: 'signIn.termsCondition',
      textClass: 'font-w-600 term-policy-text',
      inputType: 'link',
    },
    {
      text: 'general.and',
      textClass: '',
      inputType: 'text',
    },
    {
      text: 'signIn.privacyPolicy',
      textClass: 'font-w-600 term-policy-text',
      inputType: 'link',
    },
  ];

  form: FormGroup;
  headerCustomClass = '';
  headerStatus: string;
  invalidPassword = false;
  loadingSubscription?: Subscription;
  loadingStatus = false;
  loginEmail = false;
  showPassword = false;
  showSpinner = false;
  returnUrl = '';
  rememberMe$?: Observable<boolean | undefined>;

  /**
   * Get SocialMedia Config
   */
  get isSSOActivated() {
    return this.config.features.sso;
  }

  /**
   * Get if the Sign Up is activated
   */
  get isSignUpActivated(): boolean {
    return this.config.features.signUp;
  }

  constructor(
    @Inject(CoreAuthConfigProvider.providerToken)
    private readonly config: CoreAuthConfigProvider.providerType,
    private readonly authService: CoreAuthService,
    private readonly coreAuthSettingsRepository: CoreAuthSettingsRepository,
    private readonly userService: UserService,
    private readonly globalService: GlobalService,
    private readonly navigateService: NavigateService,
    private readonly route: ActivatedRoute,
    private readonly storeService: StoreService
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    });
    this.headerStatus = 'signIn';
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    this.rememberMe$ = this.coreAuthSettingsRepository.isRemembered;
  }

  async ionViewWillEnter() {
    this.storeService.clearAuthCodeValidationData();
  }

  /**
   * SignIn the user with Email
   */
  async signIn() {
    try {
      if (!this.form.valid) {
        return;
      }

      this.invalidPassword = false;
      this.showSpinner = true;

      await this.authService.signIn({
        email: this.form.get('email')?.value,
        password: this.form.get('password')?.value,
      });

      const user = await firstValueFrom(this.userService.userControllerGetMe());

      // update the selected company in the storage
      this.storeService.updateSelectedCompany(user.company);

      this.showSpinner = false;

      if (user.role) {
        if (this.returnUrl) {
          this.navigateService.navigateTo(this.returnUrl);
        } else {
          this.globalService.goToHome();
        }
      } else {
        console.warn(
          'Warning: Only role brand, influencer and super_admin are redirected to dashboard'
        );
        this.navigateService.navigateTo('/auth/signin');
      }
    } catch (error: any) {
      if (error.code === 'NotAuthorizedException') {
        this.invalidPassword = true;
      } else if (error.code === 'UserNotConfirmedException') {
        // The user might have leave without completing the confirmation step and is
        // now coming back trying to login
        this.storeService.updateAuthCodeValidationData({
          email: this.form.get('email')?.value,
          password: this.form.get('password')?.value,
        });

        this.navigateService.navigateTo('/auth/signup');
      }

      this.showSpinner = false;
    }
  }

  /**
   * SignIn the user with SocialMedia
   */
  signInWithSSO(provider: Parameters<typeof this.authService['signInFederated']>[0]) {
    return this.authService.signInFederated(provider);
  }

  ngOnDestroy() {
    this.loadingSubscription?.unsubscribe();
  }

  public onRememberMeChanged(event: any): void {
    this.coreAuthSettingsRepository.setIsRemembered(event.target.checked);
  }

  resetPassword() {
    this.navigateService.navigateTo('/auth/reset-password');
  }

  signup() {
    this.navigateService.navigateTo('/auth/signup');
  }
}
