import { Component, Input } from '@angular/core';

@Component({
  selector: 'custom-popover',
  templateUrl: './custom-popover.component.html',
  styleUrls: ['./custom-popover.component.scss']
})
export class CustomPopoverComponent {
  @Input() customPopoverClass = '';
  @Input() customPopover: Array<any> = [];
  @Input() sectionPopOver = false;
  @Input() svgIcon = ''

  constructor() { }
}
