<div class="banner-container">
  <!-- Logo and catch phrase -->
  <div class="logo-catch-container">
    <img class="logo-img" [src]="'kickflo/global/dark-logo_fnczfr' | media: 'image': 'original' | async"
      alt="kickflo logo" />
    <p class="mt-3">
      {{ 'signIn.catchPhrase' | translate }}
    </p>
  </div>
  <!-- Banner image -->
  <div class="banner-img">
    <img [src]="'kickflo/global/Login-Desktop_agxtqr' | media: 'image': 'optimized' | async" alt="banner image" />
  </div>
</div>
