<p class="terms-conditions-copy m-0 font-w-400" [class]="globalTermConditionClass">
  <span *ngFor="let customData of customTermsConditions; let i = index" [class]="customData.textClass">
    <ng-container [ngTemplateOutlet]="customData.inputType === inputType.LINK ? link : text"
      [ngTemplateOutletContext]="{data:customData}">
    </ng-container>
  </span>
</p>

<ng-template #link let-data='data'>
  <span class="link" (click)="onClickTermConditionPrivacy(data)"> {{ data.text | translate }}</span>
</ng-template>

<ng-template #text let-data='data'>
  {{ data.text | translate }}
</ng-template>
