import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RoleName } from '@prisma/client';
import { CoreAuthService, CoreAuthSettingsRepository } from '@serious-stack/core/auth/angular';
import { firstValueFrom } from 'rxjs';
import { NavigateService } from '../../../../services/navigate.service';
import { UserService } from '../../../../services/user.service';
import { StoreService } from '../../../../store/store.service';

@Component({
  selector: 'signup-validate-code',
  templateUrl: './kickflo-signup-validate-code.page.html',
  styleUrls: ['./kickflo-signup-validate-code.page.scss'],
})
export class SignupValidateCodeComponent {
  @Input() user = '';
  @Input() password = '';
  @Output() everythingCompleted = new EventEmitter<boolean>();
  @Output() goBack = new EventEmitter<boolean>();
  @Output() sendCode = new EventEmitter<boolean>();
  @Output() toggleShowSpinner = new EventEmitter<boolean>();

  codeQuantity = 6;
  currentLang = this.coreAuthSettingsRepository?.storeValue?.currentLanguage;
  invalidCode = false;
  isCodeExpired = false;

  constructor(
    private readonly coreAuthSettingsRepository: CoreAuthSettingsRepository,
    private readonly authService: CoreAuthService,
    private readonly userService: UserService,
    private readonly navigateService: NavigateService,
    private readonly storeService: StoreService
  ) {}

  /**
   * On click Header go back Previous Page
   */
  handleRedirection() {
    this.goBack.emit(false);
  }

  /**
   * Sign Up code
   * @param event Event emitted on fill input
   */
  async signUpCode(event: any) {
    try {
      this.invalidCode = false;
      this.isCodeExpired = false;
      this.toggleShowSpinner.emit(true);

      await this.authService.signUpConfirm({
        code: event,
        email: this.user,
      });

      await this.authService.signIn({
        email: this.user,
        password: this.password,
      });

      this.storeService.clearAuthCodeValidationData();

      const user = await firstValueFrom(this.userService.userControllerGetMe());

      switch (user.role) {
        case RoleName.brand:
          this.navigateService.navigateToRoot('/pages/admin/payment');
          break;
        case RoleName.accounting:
          this.navigateService.navigateToRoot('/pages/accounting/payment');
          break;
        case RoleName.super_admin:
          this.navigateService.navigateToRoot('/pages/brand/offers');
          break;
        case RoleName.influencer:
          this.navigateService.navigateToRoot('/pages/influencer/onboarding');
          break;
        default:
          this.navigateService.navigateTo('/auth/signin');
          break;
      }

      this.everythingCompleted.emit(true);
    } catch (error: any) {
      this.invalidCode = error.code === 'CodeMismatchException';
      this.isCodeExpired = error.code === 'ExpiredCodeException';
      this.toggleShowSpinner.emit(false);
    }
  }

  /**
   * Send Again the code
   */
  sendCodeAgain() {
    this.sendCode.emit(true);
  }
}
