import { InjectionToken } from '@angular/core';
import { Store, createState } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { persistState, localStorageStrategy } from '@ngneat/elf-persist-state';

export interface CoreFeatureFlagEntity {
  id: string;
  enabled: boolean;
  value?: string | number | boolean;
}

const name = 'feature-flags';

const { state, config } = createState(withEntities<CoreFeatureFlagEntity>({}));

const coreFeatureFlagStore = new Store({ state, name, config });

export const persist = persistState(coreFeatureFlagStore, {
  key: name,
  storage: localStorageStrategy,
});

export type providerType = typeof coreFeatureFlagStore;
export const providerToken = new InjectionToken<providerType>(
  'CORE_FEATURE_FLAG_STORE',
  {
    providedIn: 'root',
    factory: () => coreFeatureFlagStore,
  }
);
