<ion-header class="header-custom ion-no-border d-lg-none d-flex">
  <ion-toolbar class="header-color pt-1">
    <header-icon
      class="kickflo-header d-flex ion-justify-content-between"
      [headerStatus]="headerStatus"
    >
    </header-icon>
  </ion-toolbar>
</ion-header>

<ion-content class="sign-in-content d-flex">
  <ion-grid class="h-100 p-0">
    <ion-row class="h-100 m-0">
      <!-- Left banner (large screens) -->
      <ion-col
        class="p-0"
        size="4"
      >
        <sign-in-banner></sign-in-banner>
      </ion-col>

      <ion-col
        class="d-lg-flex flex-column ion-align-items-center h-100 p-0"
        size="12"
        size-lg="8"
      >
        <!-- Top right - Create account (large screens) -->
        <div class="login-no-account d-none d-lg-flex ion-justify-content-end w-100">
          {{ 'signIn.noAccount' | translate }}
          <a
            class="ms-1 login-create-account clickable"
            (click)="signup()"
          >
            {{ 'signIn.createAccount' | translate }}
          </a>
        </div>

        <div class="d-flex flex-column sign-in-form h-100 w-lg-50">
          <!-- Title/subtitle -->
          <div
            class="ion-align-items-center ion-align-items-lg-start mt-4 mb-lg-0 d-flex flex-column w-100"
          >
            <div class="login-header-title ion-text-center mb-1">
              {{ 'signIn.login' | translate }}
            </div>
            <div class="login-header-subtitle">{{ 'signIn.loginSubtitle' | translate }}</div>
          </div>

          <div
            class="d-flex flex-column mt-lg-3 w-100"
            size="12"
          >
            <!-- SSO buttons -->
            <div
              class="sso-buttons-container d-lg-flex flex-column flex-lg-row mt-5 my-lg-0"
              [ngClass]="!loginEmail && isSSOActivated ? 'd-flex' : 'd-none'"
            >
              <!-- Continue with email (smaller screens) -->
              <sso-button
                class="d-lg-none"
                imageSrc="assets/icon/custom/email-icon.svg"
                [text]="'signIn.signInToYourEmail' | translate"
                testId="login-button-withEmail"
                (btnClick)="loginEmail = true"
              >
              </sso-button>
              <!-- Continue with Facebook -->
              <sso-button
                class="my-2 my-lg-0 me-lg-2"
                [imageSrc]="'kickflo/global/facebook_xava0y' | media: 'image' | async"
                [text]="'signIn.signInWithFacebook' | translate"
                testId="login-button-withFacebook"
                (btnClick)="signInWithSSO('facebook')"
              >
              </sso-button>
              <!-- Continue with Instagram (minimal on larger screens) -->
              <sso-button
                class="my-lg-0"
                [imageSrc]="'kickflo/global/instagram_rphkqc' | media: 'image' | async"
                [text]="'signIn.signInWithInstagram' | translate"
                testId="login-button-withInstagram"
                [isMinimal]="true"
                (btnClick)="signInWithSSO('google')"
              >
              </sso-button>
            </div>

            <!-- Divisor -->
            <div
              class="ion-align-items-center d-lg-flex d-none flex-row ion-justify-content-center mb-1 mt-2 my-lg-5 w-100"
            >
              <span class="divisor-line"></span>
              <p class="my-0 mx-2 divisor-text">{{ 'general.or' | translate | uppercase }}</p>
              <span class="divisor-line"></span>
            </div>

            <form
              class="d-lg-flex flex-column mt-4 mt-lg-0"
              [ngClass]="loginEmail
                    ? 'd-flex'
                    : 'd-none'"
              id="test"
              [formGroup]="form"
              (ngSubmit)="signIn()"
            >
              <!-- #region Reusable Input item - Email Address -->
              <ion-item
                class="input-item"
                lines="none"
              >
                <ion-label
                  class="input-title"
                  position="floating"
                >
                  {{ 'forms.email' | translate | titlecase }}
                </ion-label>
                <ion-input
                  class="input-field ps-2 py-1 py-lg-0-5 opacity-100"
                  [class.invalid]="!form.controls.email.valid
                          && (form.controls.email.dirty
                          || form.controls.email.touched)
                          || invalidPassword"
                  type="email"
                  (ionChange)="invalidPassword = false"
                  formControlName="email"
                  [placeholder]="'forms.email' | translate | titlecase"
                  inputmode="email"
                  data-testId="login-input-email"
                  autocomplete="email"
                  required="true"
                >
                </ion-input>
              </ion-item>
              <!-- Errors -->
              <div
                *ngIf="form.controls.email.invalid
                  && (form.controls.email.dirty || form.controls.email.touched)"
              >
                <!-- Required -->
                <div
                  class="input-error"
                  data-testId="login-label-errorEmailRequired"
                  *ngIf="form.controls.email.errors?.['required']"
                >
                  {{ 'forms.requiredField' | translate }}
                </div>
                <!-- Invalid format -->
                <div
                  class="input-error"
                  data-testId="login-label-errorInvalidEmail"
                  *ngIf="form.controls.email.errors?.['email']"
                >
                  {{ 'forms.invalidEmail' | translate }}
                </div>
              </div>
              <!-- #endregion -->

              <!-- #region Reusable Input item - Password -->
              <ion-item
                class="input-item mt-2 mt-lg-3-5"
                lines="none"
              >
                <ion-label
                  class="input-title"
                  position="floating"
                >
                  {{ 'signIn.password' | translate | titlecase }}
                </ion-label>
                <ion-input
                  class="input-field password ps-2 py-1 py-lg-0-5 opacity-100"
                  [class.invalid]="!form.controls.password.valid
                      && (form.controls.password.dirty || form.controls.password.touched)
                      || invalidPassword"
                  [type]="showPassword ? 'text' : 'password'"
                  formControlName="password"
                  [placeholder]="'signIn.password' | translate | titlecase"
                  inputmode="password"
                  autocomplete="password"
                  required="true"
                  data-testId="login-input-password"
                >
                  <div class="password-eye">
                    <i-feather
                      [hidden]="showPassword"
                      name="eye"
                      class="eye-icon"
                    ></i-feather>
                    <i-feather
                      [hidden]="!showPassword"
                      name="eye-off"
                      class="eye-icon"
                    ></i-feather>
                  </div>
                </ion-input>
                <!-- Clickable overlay with transparent background (hack) -->
                <div
                  class="password-eye-clickable"
                  (click)="showPassword = !showPassword"
                ></div>
              </ion-item>
              <!-- Errors -->
              <div
                *ngIf="form.controls.password.invalid
                  && (form.controls.password.dirty || form.controls.password.touched)"
              >
                <!-- Required -->
                <div
                  class="input-error"
                  data-testId="login-label-errorPasswordRequired"
                  *ngIf="form.controls.password.errors?.['required']"
                >
                  {{ 'forms.requiredField' | translate }}
                </div>
              </div>
              <!-- Invalid password -->
              <div
                class="input-error"
                data-testId="login-label-errorInvalidPassword"
                *ngIf="invalidPassword"
              >
                {{ 'forms.invalidPassword' | translate }}
              </div>
              <!-- #endregion -->

              <!-- Remember me -->
              <ion-item class="login-remember-me my-4 mt-lg-3-5 mb-lg-5">
                <ion-label class="login-remember-me-label ion-text-end">
                  {{ 'signIn.rememberMe' | translate }}
                </ion-label>
                <ion-toggle
                  class="p-0 ms-1"
                  [checked]="rememberMe$ | async"
                  (ionChange)="onRememberMeChanged($event)"
                  data-testId="login-toggle-rememberMe"
                >
                </ion-toggle>
              </ion-item>

              <!-- Login Button -->
              <div class="mt-lg-1">
                <ion-button
                  type="submit"
                  class="w-100 primary-button sign-in-button"
                  [disabled]="!form.valid"
                  data-testId="login-button-signIn"
                >
                  {{ 'signIn.login' | translate }}
                </ion-button>
              </div>
            </form>

            <!-- Reset Password -->
            <div
              class="d-lg-flex mt-3"
              [ngClass]="loginEmail ? 'd-flex' : 'd-none'"
            >
              <a
                class="login-reset-password ion-text-center w-100 clickable"
                data-testId="login-button-forgotPassword"
                (click)="resetPassword()"
              >
                {{ 'signIn.resetPassword' | translate }}
              </a>
            </div>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border footer-global d-lg-none">
  <ion-toolbar *ngIf="!loginEmail">
    <terms-conditions
      [globalTermConditionClass]="'ion-text-center'"
      [customTermsConditions]="customTermsConditionsData"
      class="d-flex d-lg-none mb-4 px-3"
    >
    </terms-conditions>
  </ion-toolbar>
</ion-footer>

<!-- Fullscreen spinner -->
<custom-spinner *ngIf="showSpinner"></custom-spinner>
