import { Component, OnDestroy, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Observable } from 'rxjs';
import { StoreRepository } from './store';

@Component({
  selector: 'kickflo-frontend-root',
  templateUrl: 'kickflo-frontend.component.html',
  styleUrls: ['kickflo-frontend.component.scss'],
})
export class KickfloFrontendComponent implements OnInit, OnDestroy {
  isLoading$?: Observable<boolean>;

  constructor(
    private readonly storage: Storage,
    private readonly storeRepository: StoreRepository
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.storage.create();
    this.isLoading$ = this.storeRepository.isLoading$;
  }

  public async ngOnDestroy(): Promise<void> {
    this.storage.clear();
  }
}
