import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sso-button',
  templateUrl: './sso-button.component.html',
  styleUrls: ['./sso-button.component.scss']
})
export class SsoButtonComponent {
  /** The path to the image (can be an URL or the path to a local file). */
  @Input() imageSrc: string | null | undefined = null;
  /** The button's text (should already be translated). */
  @Input() text = '';
  /** The button's data test id. */
  @Input() testId = '';
  /** Whether or not the button is minimal (logo only). */
  @Input() isMinimal = false;

  /** On button click event. */
  @Output() btnClick = new EventEmitter();

  onClick() {
    this.btnClick.emit();
  }
}
