import { Inject, Injectable } from '@angular/core';
import { updateRequestStatus, selectRequestStatus } from '@ngneat/elf-requests';
import { CoreAuthSettingsStoreProvider, CoreAuthStoreProvider } from '../providers';
import { Observable } from 'rxjs';
import { AuthRequest } from '../providers/core-auth-store.provider';

@Injectable({
  providedIn: 'root',
})
export class CoreAuthRepository {
  constructor(
    @Inject(CoreAuthStoreProvider.providerToken)
    public authStore: CoreAuthStoreProvider.providerType,
    @Inject(CoreAuthSettingsStoreProvider.providerToken)
    public authSettingsStore: CoreAuthSettingsStoreProvider.providerType
  ) {}

  public get store() {
    return this.authStore;
  }

  public get storeValue() {
    return this.authStore.getValue();
  }

  public selectRequestStatus(request: CoreAuthStoreProvider.AuthRequest): Observable<{
    value: 'idle' | 'pending' | 'success' | 'error';
    error?: Error;
  }> {
    return this.authStore.pipe<any>(selectRequestStatus(request));
  }

  updateRequestStatus(
    key: AuthRequest,
    value: Parameters<typeof updateRequestStatus>[1] | 'error',
    error?: Parameters<typeof updateRequestStatus>[2]
  ) {
    return this.authStore.update(updateRequestStatus(key as AuthRequest, value as any, error));
  }

  public update(identity: any) {
    this.authStore.update((state) => ({
      ...state,
      ...identity,
    }));
  }

  public reset() {
    this.authStore.reset();
  }
}
