<div class="d-lg-flex flex-column">
  <div class="d-flex flex-row ion-justify-content-center" [class]="codeNumberClass">
    <ion-item
      *ngFor="let count of counter(codeNumberQuantity); let i = index"
      #codeItems
      class="input-code-item"
      lines="none"
    >
      <ion-input
        class="input-code-field opacity-100 ion-text-center"
        data-testId="confirmEmail-input-code"
        #singleCodeInput
        (paste)="onPaste($event)"
        [class.invalid]="
          (codeArray[i] === '' && (codeArray.length === codeNumberQuantity || codeNotCompleted)) ||
          invalidCode ||
          isCodeExpired
        "
        type="search"
        maxlength="1"
        (keypress)="decimalOnlyValidation($event)"
        (ionChange)="fillCodeValue(i, $event)"
        (ionFocus)="changePosition(i, $event)"
        [value]="codeArray[i]"
        inputmode="search"
        required="true"
      >
      </ion-input>
    </ion-item>
    <ion-input class="d-none" [value]="codeInput" #codeInput required="true"> </ion-input>
  </div>
  <!-- Invalid code -->
  <div
    class="input-error mt-1 ion-text-center"
    data-testId="signup-lbl-invalidConfirmationCode"
    *ngIf="invalidCode || isCodeExpired"
  >
    {{
      (invalidCode ? 'resetPassword.invalidCode' : 'signUp.validationCodeExpiredError') | translate
    }}
  </div>
</div>
